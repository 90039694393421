import { Layout } from '../../components/core';
import { InputMonitorWarning } from '../../content/assessment/components/InputMonitorWarning';
import { getRoute, useNavigate } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { DemographicsForm } from './forms/DemographicsForm';
import { useFindCareRedirect } from './hooks/useFindCareRedirect';

export const FindCareDemographicsPage = (): JSX.Element => {
  useTitle(['About you', 'Find Care']);

  useFindCareRedirect();

  const navigate = useNavigate();

  const onBack = (): void => {
    navigate(getRoute('findCareConfirmInfo', {}), { state: { redirect: false } });
  };

  return (
    <FindCarePageWrapper testID="find-care-user-demographics-page" onBackPress={onBack}>
      <Layout.VStack space={8}>
        <DemographicsForm />

        <InputMonitorWarning isVisible={true} />
      </Layout.VStack>
    </FindCarePageWrapper>
  );
};
