import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import Sentry from './sentry';

export type PageTitle = string | string[] | undefined;

// Null means the page just loaded and nothing has been initialized yet (expected behavior).
// Undefined means the page did not set a title or the page is de-rendering.
export type InternalPageTitle = string[] | undefined | null;

// Since the page title is not tracked through react (in the document instead)
// this is not using react states. This simplifies the code drastically.
let pageTitle: InternalPageTitle = null;

export const getPageTitle = (): InternalPageTitle => {
  return pageTitle;
};

const setTitle = (newTitle: InternalPageTitle): void => {
  if (isEqual(pageTitle, newTitle)) {
    return;
  }

  if (newTitle == null) {
    pageTitle = undefined;
    return;
  }

  pageTitle = newTitle.slice(0);

  const finalTitle = newTitle.concat('Mantra Health').join(' | ');

  if (Platform.OS === 'web') {
    document.title = finalTitle;
  }
};

const resetTitle = (prevTitle: InternalPageTitle): void => {
  if (isEqual(pageTitle, prevTitle)) {
    setTitle(undefined);
  }
};

const castTitle = (title: PageTitle): InternalPageTitle => {
  if (title == null || title.length < 1) {
    return undefined;
  }
  return Array.isArray(title) ? title : [title];
};

export const useTitle = (title: PageTitle): void => {
  const newTitle = useMemo(() => castTitle(title), [title]);

  // Do not put this in the useEffect or the PageContent validation won't work correctly.
  // It is a little less efficient to have this trigger on each render, but we need it to happen
  // immediately when this hook is called.
  setTitle(newTitle);

  useEffect(() => {
    return () => {
      resetTitle(newTitle);
    };
  }, []);
};

/**
 * Validates that a page title was set using useTitle().
 */
export const validateTitle = (testID: string): void => {
  if (pageTitle === undefined) {
    const errorMessage = `Page title missing for testID '${testID}'`;

    // eslint-disable-next-line no-console
    console.error(errorMessage);
    Sentry.captureException(new Error(errorMessage));
  }
};
