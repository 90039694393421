import { isEmpty, map, values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Heading, Layout, Text } from '../../components/core';
import { PageError } from '../../components/page';
import { useFindCareFinishMutation } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { useFindCareContext } from './context/findCareContext';
import { useFindCareRedirect } from './hooks/useFindCareRedirect';

export const FindCareReviewAnswersPage = (): JSX.Element => {
  useTitle(['Reviewing your Answers', 'Find Care']);

  const navigate = useNavigate();
  const { answersByAssessment } = useFindCareContext();
  const [pageError, setPageError] = useState(false);

  const [saveAssessment] = useFindCareFinishMutation({
    onCompleted: () => {
      navigate(getRoute('findCareRecommendation', {}));
    },
    onError: () => {
      setPageError(true);
    },
  });

  useFindCareRedirect();

  useEffect(() => {
    const submissions = map(answersByAssessment, (answers, questionnaireKey) => {
      return {
        questionnaireKey,
        answers: values(answers),
      };
    });

    // When a user presses the back button in browser, they can re-visit /review-answers route from the recommendation page
    // When they do, the submission is empty, and we only want to save the assessment if there are answers to save
    if (!isEmpty(submissions)) {
      void saveAssessment({ variables: { submissions } });
    }
  }, [answersByAssessment]);

  if (pageError) {
    return <PageError />;
  }

  return (
    <FindCarePageWrapper testID="find-care-review-answer-page">
      <Layout.VStack space={4} flex={1} justifyContent="center">
        <Heading.h2 center>We're reviewing your answers...</Heading.h2>
        <Text.para center>Please do not leave this page.</Text.para>
      </Layout.VStack>
    </FindCarePageWrapper>
  );
};
