import { Divider } from 'native-base';
import React from 'react';
import { Layout, Pressable, Text } from '../../components/core';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import {
  PageContent,
  PageFooter,
  PageHeader,
  PageLoading,
  PageTopBar,
} from '../../components/page';
import { useHubOrganizationResourcesQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { useTitle } from '../../utils/useTitle';
import { CampusResource } from './components/CampusResource';

export const CampusResourcesPage = (): JSX.Element => {
  const title = 'Campus Resources';
  useTitle(title);

  const navigate = useNavigate();
  const { data: hubUserOrgResourcesData, loading } = useHubOrganizationResourcesQuery();

  if (loading) {
    return <PageLoading pageName="resources" />;
  }

  const resources = hubUserOrgResourcesData?.hubOrganizationResources ?? [];
  const nonCrisisResources = resources.filter(resource => !resource.showAsCrisisResource);

  const onBackPress = (): void => {
    navigate(getRoute('home', {}));
  };
  const onCrisisResourcesPress = (): void => {
    navigate(getRoute('crisisResources', {}));
  };

  const crisisResourcesLink = (
    <Pressable
      {...styles.crisisLink}
      isLink
      onPress={onCrisisResourcesPress}
      testID="pressable-campus-resources-go-to-crisis-resources-page"
    >
      <Layout.HStack {...styles.crisisLinkText} space={3}>
        <Text.para>
          For emergencies and crisis intervention, please see{' '}
          <Text.para bold>Crisis Resources.</Text.para>
        </Text.para>

        <IconChevronRight aria-hidden color="danger.600" size={5} minWidth={5} />
      </Layout.HStack>
    </Pressable>
  );

  return (
    <PageContent testID="campus-resources-page">
      <PageTopBar
        backAccessibilityLabel="Back to Home"
        backTitle="Home"
        onBackPress={onBackPress}
      />

      <PageHeader title={title} />

      <Layout.VStack space={4}>
        <Layout.View {...styles.resourcesNotice}>
          <Text.para>These resources are not affiliated with Mantra Health</Text.para>
        </Layout.View>

        {crisisResourcesLink}

        <Layout.VStack
          {...styles.resourcesStack}
          aria-label="Campus resources"
          divider={<Divider aria-hidden backgroundColor="secondary.alpha.10:alpha.10" />}
          role="list"
          space={10}
        >
          {nonCrisisResources.map(resource => (
            <CampusResource key={resource.name} headingLevel={2} resource={resource} />
          ))}
        </Layout.VStack>

        <PageFooter />
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  resourcesNotice: {
    backgroundColor: 'primary.100',
    borderRadius: 4,
    padding: 4,
  },

  crisisLink: {
    backgroundColor: 'danger.alpha.20:alpha.20',
    borderRadius: 4,
    padding: 4,
  },

  crisisLinkText: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  resourcesStack: {
    paddingTop: 10,
  },
});
