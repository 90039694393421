import { kebabCase } from 'lodash';
import React from 'react';
import { Heading, Layout, Pressable, Text } from '../../../components/core';
import { useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type QuickActionProps = {
  icon: JSX.Element;
  ariaTitle?: string;
  title: string;
  caption: string;
  linkTo: string;
};

export const QuickActionCard = ({
  icon,
  ariaTitle = '',
  title,
  caption,
  linkTo,
}: QuickActionProps): JSX.Element => {
  const navigate = useNavigate();

  const onClickNav = () => (): void => {
    navigate(linkTo);
  };

  const titleAsId = kebabCase(title).toLowerCase();

  const headingId = `${titleAsId}-heading`;
  const captionId = `${titleAsId}-caption`;

  const ariaHeaderProps = ariaTitle ? { 'aria-label': ariaTitle } : {};

  return (
    <Layout.Flex {...styles.quickAction} role="listitem">
      <Pressable
        {...styles.pressable}
        aria-labelledby={headingId}
        aria-describedby={captionId}
        isLink
        onPress={onClickNav()}
        testID={`pressable-home-page-go-to-${titleAsId}-page`}
      >
        <Layout.VStack {...styles.topStack} space={4}>
          {icon}

          <Layout.VStack alignItems="center" space={2}>
            <Heading.h4 {...styles.text} center id={headingId} {...ariaHeaderProps}>
              {title}
            </Heading.h4>

            <Text.caption center id={captionId}>
              {caption}
            </Text.caption>
          </Layout.VStack>
        </Layout.VStack>
      </Pressable>
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  quickAction: {
    alignItems: 'center',
    backgroundColor: 'secondary.50',
    borderRadius: 4,
    flexGrow: 1,
    flexShrink: 1,
    minWidth: '45%',
    shadow: 'large',
  },

  pressable: {
    alignItems: 'center',
    flexGrow: 1,
    paddingX: 4,
    paddingY: 6,
    width: '100%',
  },

  topStack: {
    alignItems: 'center',
  },

  text: {
    width: '100%',
    maxWidth: '130px',
  },
});
