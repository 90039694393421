import { defaultTo, trim } from 'lodash';
import { useToast } from 'native-base';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard } from 'react-native';
import { Heading, Layout, Link, Text } from '../../../../../components/core';
import { FormTextArea } from '../../../../../components/form';
import { IconBook } from '../../../../../components/icons/IconBook';
import { ConfirmLeaveModal } from '../../../../../components/modals/ConfirmLeaveModal';
import { RichText } from '../../../../../components/prismic';
import { usePortalContext } from '../../../../../contexts/portalContext';
import { useAnswerSkillReflectionQuestionMutation } from '../../../../../graphQL';
import { getRoute, useNavigate } from '../../../../../routes';
import { getStylesheet } from '../../../../../styles';
import { Portals } from '../../../../portal/portals';
import { ReflectionSlideSlice } from '../../../SkillTypes';
import { SkillWrapper } from '../../SkillWrapper';
import type { SliceContext } from '../../skillTypes';

type ReflectionSlideProps = {
  context: SliceContext;
  slice: ReflectionSlideSlice;
};

export const ReflectionSlide = ({ context, slice }: ReflectionSlideProps): JSX.Element => {
  const navigate = useNavigate();
  const { onlyIfPortalIsActive } = usePortalContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const mainItem = slice.primary;
  const questionKey = trim(defaultTo(mainItem.question_key, ''));
  const questionText = trim(defaultTo(mainItem.question, ''));

  const reflectionQuestion = context.questions?.find(
    existingQuestion => existingQuestion.questionKey === questionKey,
  );

  const coachingEnabled = onlyIfPortalIsActive(Portals.Coaching, true, false);

  const toast = useToast();

  const formContext = useForm<{ answer: string }>({
    defaultValues: {
      answer: reflectionQuestion?.answer ?? '',
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = formContext;

  const answerValue = watch('answer');

  const [submitAnswer] = useAnswerSkillReflectionQuestionMutation({
    onError: () => {
      toast.show({
        description: 'Response could not be saved',
        duration: 3000,
      });
    },
    onCompleted: ({ answerSkillReflectionQuestion }) => {
      if (trim(answerValue) === '') {
        // If the answer was not updated but there is no answer, don't display a toast at all.
        // This means the user has never answered the question.
        return;
      }

      if (!answerSkillReflectionQuestion) {
        toast.show({
          description: 'Response could not be saved',
          duration: 3000,
        });
        return;
      }

      toast.show({
        description: 'Response saved',
        duration: 3000,
      });
    },
  });

  const onContinue = (triggerNextSlide: () => void): void => {
    Keyboard.dismiss();

    void handleSubmit(async (values): Promise<void> => {
      if (!questionKey) {
        triggerNextSlide();
        return;
      }

      const trimmedValue = trim(values.answer);

      await submitAnswer({
        variables: {
          skillId: context.skillId,
          questionKey,
          question: questionText,
          answer: trimmedValue,
        },
      });

      triggerNextSlide();
    })();
  };

  const onCloseConfirmModalPress = (): void => {
    setShowConfirmModal(false);
  };

  const onCrisisResourcesLeaveConfirmModalPress = (): void => {
    navigate(getRoute('crisisResources', {}));
  };

  const helperText = context.isVisible ? (
    <Text.caption color="secondary.alpha.80:alpha.80">
      {coachingEnabled
        ? 'Note: Your responses are visible to your coach, but they are not monitored in real-time. If you are in need of immediate assistance, please visit our'
        : 'Note: Your responses are confidential and will not be reviewed for safety purposes or crisis response. If you need immediate support, please visit these'}{' '}
      <Link.caption onPress={() => setShowConfirmModal(true)} to={getRoute('crisisResources', {})}>
        crisis resources
      </Link.caption>
      .
    </Text.caption>
  ) : undefined;

  return (
    <SkillWrapper isVisible={context.isVisible} onContinue={onContinue}>
      {showConfirmModal && (
        <ConfirmLeaveModal
          onClose={onCloseConfirmModalPress}
          onConfirm={onCrisisResourcesLeaveConfirmModalPress}
        />
      )}
      <Layout.VStack space={4}>
        <Layout.HStack {...styles.reflection} space={2}>
          <IconBook size={4} />
          <Heading.h6>Reflection</Heading.h6>
        </Layout.HStack>

        <Heading.h2>{questionText}</Heading.h2>

        {mainItem.description !== undefined && <RichText field={mainItem.description} />}

        {mainItem.placeholder != null && <Text.para>{mainItem.placeholder}</Text.para>}

        <Layout.VStack space={2}>
          <FormTextArea
            {...styles.textArea}
            control={control}
            error={errors.answer}
            helperText={helperText}
            isDisabled={!context.isVisible} // Make sure this isn't target-able if it isn't visible.
            label="Enter your response"
            name="answer"
            necessityIndicator
            rules={{
              maxLength: {
                value: 4040,
                message: 'Maximum length of 4040 characters reached.',
              },
            }}
            testID="reflection-text-area"
          />
        </Layout.VStack>
      </Layout.VStack>
    </SkillWrapper>
  );
};

export default ReflectionSlide;

const styles = getStylesheet({
  reflection: {
    display: 'flex',
    alignItems: 'center',
  },

  textArea: {
    backgroundColor: 'white:alpha.60',
    fontSize: 'md',
    minHeight: 140,
  },
});
