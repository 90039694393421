import { JSX, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Text } from '../../components/core';
import { PageContent, PageError, PageFooter, PageTopBar } from '../../components/page';
import { getRoute, useSearchParams } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { NewPasswordForm } from './forms/NewPasswordForm';

export const NewPasswordPage = (): JSX.Element => {
  useTitle('Set a new password');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isPostSubmit, setIsPostSubmit] = useState(false);

  const token = searchParams.get('token') ?? '';
  const tokenId = searchParams.get('tokenId') ?? '';

  const onSuccess = (): void => {
    setIsPostSubmit(true);
  };

  const onBackPress = (): void => {
    navigate(getRoute('login', {}));
  };

  if (!token || !tokenId) {
    return <PageError showContact />;
  }

  return (
    <PageContent paddingTop={4} pageSize="medium" testID="new-password-page">
      {!isPostSubmit && (
        <>
          <PageTopBar
            backAccessibilityLabel="Back to Login"
            backTitle="Back"
            onBackPress={onBackPress}
            paddingBottom={8}
          />

          <Layout.VStack space={4}>
            <Heading.h3>Set a new password</Heading.h3>

            <Text.para>
              Passwords must be at least 8 characters and include at least one uppercase letter, one
              lower case letter, one number and one special character (!, @, #, $, %, ^, &, *)
            </Text.para>

            <NewPasswordForm token={token} tokenId={tokenId} onSuccess={onSuccess} />
          </Layout.VStack>
        </>
      )}

      {isPostSubmit && (
        <Layout.VStack space={8}>
          <Layout.VStack space={4}>
            <Heading.h3>Password updated</Heading.h3>

            <Text.para>Your password has been updated successfully.</Text.para>
          </Layout.VStack>

          <Button.secondaryMedium testID="button-new-password-back-to-login" onPress={onBackPress}>
            Back to Login
          </Button.secondaryMedium>
        </Layout.VStack>
      )}

      <PageFooter />
    </PageContent>
  );
};
