import { JSX } from 'react';
import { Button, Heading, Layout, Link, Text } from '../../../components/core';
import { useConsentRequiredUserQuery } from '../../../graphQL';
import { useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { ImageRobotWithMessage } from '../images/ImageRobotWithMessage';

const MAIL_TO =
  'mailto:hi@mantrahealth.com?subject=Parent%20or%20Guardian%20Consent%20Needed&body=Hello%2C%0A%0AI%20am%20a%20student%20at%20SchoolName%20and%20am%20attempting%20to%20access%20the%20Mantra%20Care%20Hub.%20However%2C%20I%20am%20not%20yet%2018%2C%20so%20I%20need%20to%20receive%20parent%20or%20guardian%20consent%20first.%0A%0ACould%20you%20please%20assist%20me%20in%20starting%20this%20process%3F%0A';

export const AgeConsentRequired = (): JSX.Element => {
  const externalNavigate = useExternalNavigate();

  const { data: userData, loading } = useConsentRequiredUserQuery();

  const onRequestForms = (): void => {
    const orgName = encodeURI(
      userData?.onboardingHubUser.organization?.name ?? 'a Mantra partner institution',
    );

    externalNavigate(MAIL_TO.replace('SchoolName', orgName));
  };

  return (
    <Layout.VStack space={8}>
      <Layout.VStack {...styles.textStack} space={4}>
        <Layout.Flex alignSelf="center">
          <ImageRobotWithMessage />
        </Layout.Flex>

        <Heading.h3 level={1}>Parent or guardian consent is required</Heading.h3>

        <Text.para>
          Because you’re under the age of 18, we’re legally required to gather more information in
          order to connect you with care.
        </Text.para>

        <Text.para>
          Please contact our care navigation team, who can guide you through the process.
        </Text.para>
      </Layout.VStack>

      <Layout.VStack space={4}>
        <Button.primaryLarge
          aria-label="Request consent forms (opens email app)"
          isLink
          isLoading={loading}
          onPress={onRequestForms}
          testID="button-age-blocked-request-form"
        >
          Request consent forms
        </Button.primaryLarge>

        <Text.para center>
          or email us at{' '}
          <Link.paraSmall bold to="mailto:hi@mantrahealth.com">
            hi@mantrahealth.com
          </Link.paraSmall>
        </Text.para>
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  textStack: {
    alignItems: 'stretch',
  },
});
