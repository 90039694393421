import { format } from 'date-fns';
import { Divider } from 'native-base';
import { useState } from 'react';
import { Button, Heading, Layout, Pagination, Pressable, Text } from '../../components/core';
import { IconArrowLeft } from '../../components/icons/IconArrowLeft';
import { IconChevronRight } from '../../components/icons/IconChevronRight';
import { PageContent, PageError, PageFooter, PageLoading } from '../../components/page';
import { useSkillReflectionGetAllForUserQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { useTitle } from '../../utils/useTitle';
import { ReflectionsHeader } from './ReflectionsHeader';

export const ReflectionsPage = (): JSX.Element => {
  useTitle('Reflections');

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: reflectionsData,
    loading,
    error,
    refetch,
  } = useSkillReflectionGetAllForUserQuery({
    variables: {
      page: currentPage,
      perPage: 10,
    },
  });

  if (error !== undefined) {
    return <PageError onRefreshPress={refetch} />;
  }
  if (loading) {
    return <PageLoading pageName="reflections" />;
  }
  if (reflectionsData === undefined) {
    return <PageError onRefreshPress={refetch} />;
  }

  const { totalPages } = reflectionsData.skillReflectionGetAllForUser.pagination;
  const reflections = reflectionsData.skillReflectionGetAllForUser.reflections;

  const onReflectionPress = (reflectionId: string) => (): void => {
    navigate(getRoute('reflection', { reflectionId }));
  };

  const onBackToSelfCarePress = (): void => {
    navigate(getRoute('selfCareHome', {}));
  };

  return (
    <PageContent testID="reflections-page">
      <ReflectionsHeader />

      <Layout.VStack {...styles.topWrapper} space={0}>
        {reflections.length === 0 && (
          <Layout.VStack {...styles.noEntries} space={6}>
            <Layout.VStack space={2}>
              <Heading.h4 center>No entries</Heading.h4>

              <Text.para center>
                Start building your skills and your reflections will be saved here.
              </Text.para>
            </Layout.VStack>

            <Button.tertiaryMedium
              leftIcon={<IconArrowLeft accessibilityHidden />}
              testID="button-reflections-page-go-back"
              onPress={onBackToSelfCarePress}
            >
              Back to Self Care
            </Button.tertiaryMedium>
          </Layout.VStack>
        )}

        {reflections.length > 0 && (
          <>
            <Divider backgroundColor="secondary.alpha.10:alpha.10" />

            <Layout.VStack
              {...styles.topStack}
              space={0}
              divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
            >
              {reflections.map(reflection => (
                <Pressable
                  {...styles.reflection}
                  key={`${reflection.title}-${reflection.updatedAt}`}
                  testID="reflections-go-to-reflection"
                  onPress={onReflectionPress(String(reflection.id))}
                >
                  <Layout.HStack
                    space={4}
                    divider={<Divider backgroundColor="secondary.alpha.10:alpha.10" />}
                  >
                    <Text.paraSmall>
                      {format(new Date(reflection.updatedAt), 'MMM dd')}
                    </Text.paraSmall>

                    <Heading.h5>{reflection.title}</Heading.h5>
                  </Layout.HStack>

                  <IconChevronRight size={4} />
                </Pressable>
              ))}
            </Layout.VStack>

            <Divider backgroundColor="secondary.alpha.10:alpha.10" />
          </>
        )}
      </Layout.VStack>

      <Pagination
        {...styles.pagination}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  topWrapper: {
    alignItems: 'center',
  },

  noEntries: {
    backgroundColor: 'secondary.200',
    paddingBottom: 5,
    paddingTop: 8,
    paddingX: 6,
    width: '100%',
  },

  topStack: {
    width: '100%',
  },

  reflection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingX: 4,
    paddingY: 8,
  },

  pagination: {
    alignSelf: 'center',
  },
});
