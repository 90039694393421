import { defaultTo } from 'lodash';
import { PresenceTransition } from 'native-base';
import { useState } from 'react';
import { Button, Image, Layout } from '../../../../../components/core';
import { IconChevronsDown } from '../../../../../components/icons/IconChevronsDown';
import { IconChevronsUp } from '../../../../../components/icons/IconChevronsUp';
import { RichText } from '../../../../../components/prismic';
import { getStylesheet } from '../../../../../styles';
import { InfoSlideSlice } from '../../../SkillTypes';
import { getInfoSlideImageAlt, getInfoSlideImageUrl } from '../../../SkillUtils';
import { SkillWrapper } from '../../SkillWrapper';
import type { SliceContext } from '../../skillTypes';

type InfoSlideProps = {
  context: SliceContext;
  slice: InfoSlideSlice;
};

export const InfoSlide = ({ context, slice }: InfoSlideProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const onReadMoreOrLessPressed = (): void => {
    setIsOpen(prevState => !prevState);
  };

  const mainItem = slice.primary;
  const imageUrl = getInfoSlideImageUrl(mainItem, 'main_image');
  const moreInformation = defaultTo(
    'more_information' in mainItem ? mainItem.more_information : undefined,
    [],
  );

  const hasMoreInfo = moreInformation.length > 0;

  const animationTop = {
    initial: {
      opacity: 0,
      translateY: -100,
    },
    animate: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 250,
      },
    },
  };
  const animationBottom = {
    initial: {
      opacity: 0,
      translateY: 100,
    },
    animate: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 250,
      },
    },
  };

  const isTitleSlide = slice.variation === 'title';
  const contentStyles = isTitleSlide ? ({ text: { textAlign: 'center' } } as const) : undefined;

  const moreInfoId = `more-info-${context.slideIndex}-content`;

  return (
    <SkillWrapper isVisible={context.isVisible} center={isTitleSlide}>
      <PresenceTransition visible={!isOpen} {...animationTop}>
        {!isOpen && (
          <>
            {imageUrl && (
              <Image
                {...styles.image}
                alt={getInfoSlideImageAlt(mainItem)}
                aria-hidden
                source={{
                  uri: imageUrl,
                }}
                resizeMode="contain"
                resizeMethod="auto"
              />
            )}
            <RichText field={mainItem.content} styles={contentStyles} />
          </>
        )}
      </PresenceTransition>

      {hasMoreInfo && (
        <Layout.View {...styles.readMore}>
          <PresenceTransition visible={isOpen} {...animationBottom}>
            {isOpen && (
              <Layout.View marginTop={6} nativeID={moreInfoId}>
                <RichText field={moreInformation} />
              </Layout.View>
            )}
          </PresenceTransition>

          <Button.tertiaryMedium
            {...styles.readButton}
            aria-expanded={isOpen}
            aria-controls={moreInfoId}
            isDisabled={!context.isVisible}
            leftIcon={
              isOpen ? (
                <IconChevronsUp aria-hidden size={5} />
              ) : (
                <IconChevronsDown aria-hidden size={5} />
              )
            }
            testID="button-skill-read-more-or-less"
            onPress={onReadMoreOrLessPressed}
          >
            {isOpen ? 'Read Less' : 'Read More'}
          </Button.tertiaryMedium>
        </Layout.View>
      )}
    </SkillWrapper>
  );
};

const styles = getStylesheet({
  image: {
    alignSelf: 'center',
    marginBottom: 8,
    size: 350,
  },
  readButton: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingX: 0,
    paddingY: 6,
  },
  readMore: {
    borderTopColor: 'secondary.alpha.20:alpha.20',
    borderTopWidth: 1,
    borderBottomColor: 'secondary.alpha.20:alpha.20',
    borderBottomWidth: 1,
    overflow: 'hidden',
  },
});

export default InfoSlide;
