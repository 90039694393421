import { JSX } from 'react';
import { Heading, Layout, Skeleton, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { IconClockFour } from '../../../components/icons/IconClockFour';
import { IconMapPin } from '../../../components/icons/IconMapPin';
import { useOnboardingUnderageErrorQuery } from '../../../graphQL';
import { getStylesheet } from '../../../styles';
import { visuallyHiddenFloatStyle } from '../../../utils/accessibility';

export const AgeBlocked = (): JSX.Element => {
  const { data: centerData, loading: loadingCenterInfo } = useOnboardingUnderageErrorQuery();

  const { departmentName, departmentAddress, departmentHours } =
    centerData?.onboardingHubUser.organizationCounselingCenter ?? {};

  const minAge = centerData?.onboardingHubUser.organizationMinimumHubAge ?? 18;

  const centerName = departmentName ?? '';
  const centerAddress = departmentAddress ?? '';
  const centerHours = departmentHours ?? '';
  const hasCenterInfo = Boolean(centerName || centerAddress || centerHours);

  return (
    <Layout.VStack space={8}>
      <Layout.VStack {...styles.textStack} space={4}>
        <IconAlertTriangle aria-hidden alignSelf="center" color="danger.600" size={7} />

        <Heading.h3 level={1}>
          We’re sorry, but you don’t meet the eligibility criteria for the Care Hub.
        </Heading.h3>

        <Text.para>
          Due to legal considerations, Mantra is unable to serve students under the age of {minAge}.
        </Text.para>

        <Text.para>Please contact your school’s counseling center for support.</Text.para>

        {loadingCenterInfo && <Skeleton height={40} />}

        {hasCenterInfo && !loadingCenterInfo && (
          <Layout.VStack {...styles.counseling} space={4}>
            {centerName && <Heading.h4 level={2}>{centerName}</Heading.h4>}

            {centerAddress && (
              <Layout.Flex {...styles.counselingItem}>
                <IconMapPin aria-hidden size={4} />

                <Heading.h6 {...visuallyHiddenFloatStyle} level={3}>
                  Address
                </Heading.h6>

                <Text.para>{centerAddress}</Text.para>
              </Layout.Flex>
            )}

            {centerHours && (
              <Layout.Flex {...styles.counselingItem}>
                <IconClockFour aria-hidden size={4} />

                <Heading.h6 {...visuallyHiddenFloatStyle} level={3}>
                  Hours
                </Heading.h6>

                <Text.para>{centerHours}</Text.para>
              </Layout.Flex>
            )}
          </Layout.VStack>
        )}
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  textStack: {
    alignItems: 'stretch',
  },

  counseling: {
    backgroundColor: 'secondary.200',
    borderColor: 'secondary.300',
    borderRadius: 8,
    borderWidth: 1,
    padding: 4,
  },

  counselingItem: {
    flexDirection: 'row',
    gap: 4,
  },
});
