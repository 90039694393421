import { ApolloProvider } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { AppRoutes } from './AppRoutes';
import { AccessibilityProvider } from './contexts/accessibilityContext';
import { BreakpointProvider } from './contexts/breakpointContext';
import { HeapProvider } from './contexts/heapContext';
import { PortalProvider } from './contexts/portalContext';
import { ScrollProvider } from './contexts/scrollContext';
import { NativeBaseProvider, PrismicProvider, setupAppPlugins } from './plugins';
import { MobileAppAccessories } from './plugins/accessories/MobileAppAccessories';
import { PushNotificationsHandler } from './plugins/pushNotifications/PushNotificationsHandler';
import { useLocation, useNavigate } from './routes';
import { logStartup } from './utils/sentry';

export const AppPlugins = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();

  const { apolloClient, errorLink } = useMemo(() => {
    logStartup('Setting up Apollo client and error link');
    const setup = setupAppPlugins();
    logStartup('Apollo setup complete');
    return setup;
  }, []);

  useEffect(() => {
    logStartup('Setting up error link navigation');
    errorLink.setNavAndLocation(navigate, location);
    logStartup('Error link navigation setup complete');
  }, [location, navigate]);

  logStartup('Rendering provider tree');

  return (
    <ApolloProvider client={apolloClient}>
      <NativeBaseProvider>
        <BreakpointProvider>
          <PrismicProvider>
            <HeapProvider>
              <PortalProvider errorLink={errorLink}>
                <GestureHandlerRootView style={{ flex: 1 }}>
                  <AccessibilityProvider>
                    <ScrollProvider>
                      <PushNotificationsHandler />
                      <MobileAppAccessories />
                      <AppRoutes />
                    </ScrollProvider>
                  </AccessibilityProvider>
                </GestureHandlerRootView>
              </PortalProvider>
            </HeapProvider>
          </PrismicProvider>
        </BreakpointProvider>
      </NativeBaseProvider>
    </ApolloProvider>
  );
};
