import { JSX, useEffect } from 'react';
import { PageLoading } from '../../components/page';
import { useMrnRouting } from '../../utils/useMrnRouting';
import { useTitle } from '../../utils/useTitle';

export const MrnPage = (): JSX.Element => {
  useTitle('Redirecting');

  const routeToMrn = useMrnRouting();

  // This will automatically route the user using the mrn search param.
  useEffect(() => {
    routeToMrn();
  }, []);

  return <PageLoading pageName="" />;
};
