import { JSX, useMemo } from 'react';
import { Button, Heading, Layout, Text } from '../../components/core';
import { IconArrowUpRight } from '../../components/icons/IconArrowUpRight';
import { PageContent, PageError } from '../../components/page';
import { useSearchParams } from '../../routes';
import { getStylesheet } from '../../styles';
import { useLaunchExternalRedirect } from '../../utils/useLaunchExternalRedirect';
import { useTitle } from '../../utils/useTitle';

export function LoginDeepLinkPage(): JSX.Element {
  useTitle('Authenticated');

  const [searchParamsOrig] = useSearchParams();
  const searchParams = new URLSearchParams(searchParamsOrig);

  // When the api has been updated to use externalRedirect instead, remove this code.
  const overrideRedirectUrl = useMemo(() => {
    /* @deprecated - Use externalRedirect param instead. */
    const androidDeepLink = searchParams.get('androidHubDeepLink') ?? '';
    if (androidDeepLink) {
      searchParams.delete('androidHubDeepLink');
      return `${androidDeepLink}/?${searchParams.toString()}`;
    }

    return undefined;
  }, [searchParams]);

  // This will open the url in the App (on mobile), or in the same tab (on web).
  const { loading, manuallyRedirect, success } = useLaunchExternalRedirect({
    overrideRedirectUrl,
  });

  if (!loading && !success) {
    return <PageError />;
  }

  return (
    <PageContent testID="login-deep-link-page">
      <Layout.VStack {...styles.stack} space={8}>
        <Heading.h3>Authentication Successful</Heading.h3>

        <Text.para>Return to the app to complete your login.</Text.para>

        <Button.primaryMedium
          testID="button-deep-link-open-app"
          rightIcon={<IconArrowUpRight accessibilityHidden />}
          onPress={manuallyRedirect}
        >
          Go to App
        </Button.primaryMedium>
      </Layout.VStack>
    </PageContent>
  );
}

const styles = getStylesheet({
  stack: {
    alignItems: 'center',
    display: 'flex',
  },
});
