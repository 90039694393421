import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ErrorLink as ApolloErrorLink, ErrorResponse } from '@apollo/link-error';
import { createClient } from 'graphql-ws';
import { compact } from 'lodash';
import { Platform } from 'react-native';
import { config } from '../config';
import { Block } from '../contexts/portalContext';
import { getRoute, Location, NavigateFunction } from '../routes';
import { getLoginUrl } from '../utils/authentication';
import { getMobileToken } from '../utils/authenticationStore';
import {
  isAgeBlocked,
  isAgeConsentRequired,
  isAgeTermsRequired,
  isGeolocationBlocked,
  isInvalidAuthentication,
  isOnboardingRequired,
} from '../utils/graphql';
import sentry from '../utils/sentry';

export type SetupApolloResponse = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  errorLink: AppErrorLink;
};

type ApolloRouting = { location: Location; navigate: NavigateFunction };
export type SetupApolloProps = ApolloRouting;

const mobileAuthHeader = setContext(async (request, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getMobileToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const versionLink = setContext((request, { headers }) => {
  const { version, build } = config;

  const baseHeaders = {
    'X-Mantra-App-Name': 'hub',
    'X-Mantra-App-Platform': Platform.OS,
    'X-Mantra-App-Version': version,
    'X-Mantra-App-Build': build,
  } as const;

  if (Platform.OS === 'web') {
    // do not include the user agent on web, we want to know what browser is used
    return {
      headers: {
        ...headers,
        ...baseHeaders,
      },
    };
  }

  // include the user agent on mobile in order to obscure the http client used
  // for security purposes
  return {
    headers: {
      ...headers,
      ...baseHeaders,
      'User-Agent': `mantra-hub-${Platform.OS}/${version}+${build}`,
    },
  };
});

const errorHandler = ({ graphQLErrors }: ErrorResponse, errorLink: AppErrorLink): void => {
  if (!graphQLErrors) {
    return;
  }

  graphQLErrors.forEach(gqlError => {
    // eslint-disable-next-line no-console
    console.log(gqlError);
  });

  if (isGeolocationBlocked({ graphQLErrors })) {
    errorLink.onBlockedError('locationRestricted');
    return;
  }

  if (isInvalidAuthentication({ graphQLErrors })) {
    // If the user is not logged in or the token is bad, force them to the login page.
    // Force to log out page instead?
    errorLink.navigateToLogin();
    return;
  }

  if (isOnboardingRequired({ graphQLErrors })) {
    // If the user has not onboarded yet, send them to the onboarding page.
    errorLink.navigateToOnboarding();
    return;
  }

  if (isAgeBlocked({ graphQLErrors })) {
    errorLink.onBlockedError('ageTooYoung');
    errorLink.navigateToHome();
    return;
  }

  if (isAgeConsentRequired({ graphQLErrors })) {
    errorLink.onBlockedError('ageConsentRequired');
    errorLink.navigateToHome();
    return;
  }

  if (isAgeTermsRequired({ graphQLErrors })) {
    errorLink.onBlockedError('ageTermsRequired');
  }
};

/**
 * Allows us to set the geolocation blocked handler after the
 * geolocation context loads as well as the location and navigate from react hooks.
 */
export class AppErrorLink extends ApolloErrorLink {
  protected blockedHandler: (block: Block) => void;

  protected location: Location | undefined;

  protected navigate: NavigateFunction;

  constructor() {
    super(apolloError => {
      errorHandler(apolloError, this);
    });

    this.blockedHandler = () => undefined;
    this.navigate = () => undefined;
    this.location = undefined;
  }

  onBlockedError(block: Block): void {
    this.blockedHandler?.(block);
  }

  setBlockedHandler(onBlocked: typeof this.blockedHandler): void {
    this.blockedHandler = onBlocked;
  }

  setNavAndLocation(navigate: NavigateFunction, location: Location): void {
    this.location = location;
    this.navigate = navigate;
  }

  navigateToLogin(): void {
    if (!this.location) {
      throw new Error('Attempt to navigate to login, but location is not defined.');
    }

    this.navigate(getLoginUrl(this.location));
  }

  navigateToOnboarding(): void {
    this.navigate(getRoute('onboarding', {}));
  }

  navigateToHome(): void {
    this.navigate(getRoute('home', {}));
  }
}

export const errorLink = new AppErrorLink();

const httpLink = new HttpLink({ uri: `${config.apiUrl}/graphql`, credentials: 'include' });

// WS Link
if (config.webSocketUrl === '') {
  sentry.captureException('Missing webSocketUrl in config');
}

const wsBaseClientOptions = {
  url: `${config.webSocketUrl}`,
};

const nativeWsClientOptions = {
  ...wsBaseClientOptions,
  connectionParams: async () => {
    const token = await getMobileToken();
    return {
      authToken: token,
    };
  },
};

const wsClientOptions = Platform.select({
  web: wsBaseClientOptions,
  default: wsBaseClientOptions,
  android: nativeWsClientOptions,
  ios: nativeWsClientOptions,
});

const wsLink = new GraphQLWsLink(createClient(wsClientOptions));

// https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const authLink: ApolloLink | undefined = Platform.select({
  web: undefined,
  default: undefined,
  android: mobileAuthHeader,
  ios: mobileAuthHeader,
});

export const setupApollo = (): SetupApolloResponse => {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from(compact([versionLink, authLink, errorLink, splitLink])),
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' },
      watchQuery: { fetchPolicy: 'no-cache' },
    },
  });

  return { apolloClient, errorLink };
};
