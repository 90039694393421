import { defaultTo } from 'lodash';
import { Center, useContrastText } from 'native-base';
import React from 'react';
import { Button, Layout, Text } from '../../components/core';
import { IconCheck } from '../../components/icons/IconCheck';
import {
  PageBubbleHeader,
  PageContent,
  PageError,
  PageFooter,
  PageLoading,
  PageTopBar,
} from '../../components/page';
import { SkillBox } from '../../content/skill/SkillBox';
import { getRoute, RouteParams, useNavigate, useParams } from '../../routes';
import { getStylesheet } from '../../styles';
import { getColorFromShade } from '../../utils/color';
import { useTitle } from '../../utils/useTitle';
import { useSkillPathwaySection } from './hooks/useSkillPathwaySection';

type SkillPathwaySectionPageParams = RouteParams<'skillPathwaySection'>;

export const SkillPathwaySectionPage = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<SkillPathwaySectionPageParams>();

  const [{ module, section, steps, completedSkillIds }, { loaded, error }] = useSkillPathwaySection(
    {
      moduleId: params.moduleId ?? '',
      sectionId: params.sectionId ?? '',
    },
  );

  useTitle(section?.title ?? 'Section');

  const backgroundColor = getColorFromShade(
    module?.color ?? '',
    module?.color_shade ?? '',
    'secondary.900',
  );

  // Determines the text color based on the background color.
  const textColor = useContrastText(backgroundColor);

  if (!loaded) {
    return <PageLoading pageName="pathway section" />;
  }
  if (error) {
    return <PageError />;
  }

  const moduleImage = module?.image;
  const moduleTitle = defaultTo(module?.title, '');
  const sectionTitle = defaultTo(section?.title, '');
  const sectionDescription = defaultTo(section?.description, []);

  const onBackPressed = (): void => {
    navigate(getRoute('skillPathway', {}));
  };

  const completedCount = steps.filter(step => completedSkillIds?.includes(step.id) ?? false).length;
  const totalCount = steps.length;
  const isComplete = completedCount >= totalCount;

  const nextSkill = steps.find(step => !completedSkillIds?.includes(step.id));
  const onNextSkillPress = (): void => {
    navigate(
      getRoute(
        'skill',
        {
          moduleId: params.moduleId ?? '',
          skillId: nextSkill?.id ?? '',
          slide: '1',
        },
        {
          path: '1',
        },
      ),
    );
  };

  return (
    <PageContent testID="skill-pathway-section-page">
      <PageTopBar
        backAccessibilityLabel="Back to your Pathway"
        backTitle="Back"
        onBackPress={onBackPressed}
        testID="skill-pathway-section-top-bar"
      >
        {isComplete ? (
          <Center {...styles.completeMessage}>
            <IconCheck size={5} />
            <Text.para {...styles.textMessage}>Complete</Text.para>
          </Center>
        ) : (
          <Text.para {...styles.progressMessage}>
            {completedCount}/{totalCount} complete
          </Text.para>
        )}
      </PageTopBar>

      <Layout.VStack space={8}>
        <Layout.VStack space={4}>
          <PageBubbleHeader
            backgroundColor={backgroundColor}
            textColor={textColor}
            image={{ alt: moduleImage?.alt ?? 'Module Image.', url: moduleImage?.url ?? '' }}
            overtext={moduleTitle}
            title={sectionTitle}
            undertextRich={sectionDescription}
            testID="skill-pathway-section-header"
          />

          <Layout.VStack {...styles.topWrapper} space={3}>
            {steps.map(step => {
              const skillIsCompleted = completedSkillIds?.includes(step.id) ?? false;

              return (
                <SkillBox
                  key={step.id}
                  skill={step}
                  moduleId={params.moduleId ?? ''}
                  skillIsCompleted={skillIsCompleted}
                  alignSelf="stretch"
                  horizontal
                  navToPathwaySkill
                />
              );
            })}
          </Layout.VStack>
        </Layout.VStack>

        {nextSkill !== undefined && (
          <Button.primaryLarge testID="button-pathway-start-next-skill" onPress={onNextSkillPress}>
            Start Next Skill
          </Button.primaryLarge>
        )}
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  topWrapper: {
    alignItems: 'center',
  },

  completeMessage: {
    flexDirection: 'row',
    display: 'flex',
  },

  textMessage: {
    color: 'green.600',
    fontWeight: 'bold',
  },

  progressMessage: {
    paddingTop: 3,
  },
});
