import React from 'react';
import { Button, Heading, Layout, SimpleModal, Text } from '../../../components/core';
import { IconGlobe } from '../../../components/icons/IconGlobe';
import { getStylesheet } from '../../../styles';

type Props = {
  onClose: () => void;
  interpretationServicesSMS: string;
};

export const InterpretationSupportModal = ({
  onClose,
  interpretationServicesSMS,
}: Props): JSX.Element => {
  const headerId = 'interpretation-support-modal-header';

  return (
    <SimpleModal headerId={headerId} size="md" onClose={onClose}>
      <Layout.VStack {...styles.modalContent} space={4}>
        <IconGlobe aria-hidden size={6} />

        <Heading.h4 id={headerId} level={1}>
          Text '1' to {interpretationServicesSMS} to request a ConnectNow Video call with
          interpretation services.
        </Heading.h4>

        <Text.para>
          American Sign Language (ASL), Mandarin, Spanish, and 240+ other languages available.
        </Text.para>

        <Text.para>
          ConnectNow Video is available 7 days a week from noon - 12am ET, excluding major holidays.
        </Text.para>

        <Button.secondaryMedium testID="interpretation-services-close" onPress={onClose}>
          Close
        </Button.secondaryMedium>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  modalContent: {
    paddingX: 4,
    paddingY: 4,
  },
});
