import { Buffer } from 'buffer';
import { chain, get, head, isEmpty, isPlainObject, omitBy, tail } from 'lodash';
import { getRoute, NavigateOptions } from '../routes';
import { NonEmptyArray } from '../types/utilityTypes';

const MRN_DELIMITER = '.';

type MrnDestination = {
  destination: string;
  existsLocally: boolean;
  params: object | undefined;
  toString: () => string;
};
type MrnDestinations = NonEmptyArray<MrnDestination>;

type Mrn = {
  destinations: Readonly<MrnDestinations>;
  toString: () => string;
};

/*
 * MRN Core
 */

const createMrn = (mrnDestinations: MrnDestination[]): Mrn | undefined => {
  if (mrnDestinations.length < 1) {
    return undefined;
  }

  return {
    // The length was validated, so it should be safe to case this to a NonEmptyArray.
    destinations: mrnDestinations as MrnDestinations,
    toString: () => stringifyMrn(mrnDestinations),
  };
};

const stringifyMrn = (mrnDestinations: MrnDestination[]): string => {
  if (mrnDestinations.length < 1) {
    return '';
  }
  return ['mrn', mrnDestinations.join(MRN_DELIMITER)].join(MRN_DELIMITER);
};

/**
 * Removes all locally-invalid destinations until a valid destination is found. Keeps any
 * locally-invalid destinations after that.
 */
const cleanNextDestination = (mrnDestinations: MrnDestination[]): MrnDestination[] => {
  let isValid = false;

  return chain(mrnDestinations)
    .map(mrnDestination => {
      if (isValid) {
        return mrnDestination;
      }

      const { existsLocally } = mrnDestination;
      if (!existsLocally) {
        // Invalid destination, remove it and try the next one.
        return undefined;
      }

      isValid = true;
      return mrnDestination;
    })
    .compact()
    .value();
};

/**
 * Decodes the mrn params from web-safe base64 encoded JSON to a plain javascript object.
 */
const decodeMrnParams = (encodedParams: string | undefined): object | undefined => {
  if (encodedParams === '' || encodedParams === undefined) {
    return undefined;
  }

  // Web-safe base64 decoding.
  const decoded = Buffer.from(
    encodedParams.replace(/-/g, '+').replace(/_/g, '/'),
    'base64',
  ).toString();

  const params = JSON.parse(decoded);
  if (params == null) {
    return undefined;
  }

  if (!isPlainObject(params)) {
    throw new Error('Invalid params');
  }

  return params as object;
};

/*
 * MRN Utils
 */
export const getNextDestination = (
  mrn: Mrn,
): { nextDestination: MrnDestination | undefined; remaining: Mrn | undefined } => {
  const destinations = cleanNextDestination(mrn.destinations);
  const otherDestinations = tail(destinations);

  return {
    nextDestination: head(destinations),
    remaining: createMrn(otherDestinations),
  };
};

/**
 * Parses an encoded mrn string into a validated MRN object.
 */
export const parseMrn = (encodedMrn: string): Mrn | undefined => {
  const items = encodedMrn.split(MRN_DELIMITER);
  if (items[0] !== 'mrn') {
    return undefined;
  }

  const mrnDestinations: MrnDestination[] = chain(items)
    .tail()
    .chunk(2)
    .map(([destination, encodedParams]) => {
      if (destination === undefined || destination === '') {
        return undefined;
      }

      let params: object | undefined;
      try {
        params = decodeMrnParams(encodedParams);
      } catch (decodeError) {
        return undefined;
      }

      return {
        destination,
        existsLocally: destination in destinationToRouteMap,
        params,
        toString: () => `${destination}${MRN_DELIMITER}${encodedParams ?? ''}`,
      };
    })
    .compact()
    .thru(cleanNextDestination)
    .value();

  return createMrn(mrnDestinations);
};

/*
 * MRN To Route
 */

const destinationToRouteMap: Record<string, RouteParser> = {
  connectNowStart: ({ mrnSearch }) => ({
    to: getRoute('connectNowConfirmInfo', {}, { ...mrnSearch }),
  }),
  home: () => ({
    to: getRoute('home', {}),
  }),
  login: ({ params, mrnSearch }) => ({
    to: getRoute(
      'login',
      {},
      mapSearchParams(params, { invalidLogin: 'invalidLogin', reauth: 'reauth' }, mrnSearch),
    ),
  }),
  loginActivationResend: ({ mrnSearch }) => ({
    to: getRoute('loginActivationResend', {}, { ...mrnSearch }),
  }),
  loginDeepLink: ({ params, mrnSearch }) => ({
    to: getRoute(
      'loginDeepLink',
      {},
      mapSearchParams(params, { externalRedirect: 'url' }, mrnSearch),
    ),
  }),
  loginNewPasswordSet: ({ params, mrnSearch }) => ({
    to: getRoute(
      'loginNewPassword',
      {},
      mapSearchParams(params, { token: 'token', tokenId: 'tokenId' }, mrnSearch),
    ),
  }),
  loginResetPasswordStart: ({ mrnSearch }) => ({
    to: getRoute('loginResetPassword', {}, { ...mrnSearch }),
  }),
  loginSsoResponse: ({ params, mrnSearch }) => ({
    to: getRoute(
      'loginSsoResponse',
      {},
      mapSearchParams(params, { token: 'token', tokenId: 'tokenId', error: 'error' }, mrnSearch),
    ),
  }),
  onDemandStart: ({ mrnSearch }) => ({
    to: getRoute('onDemandConfirmInfo', {}, { ...mrnSearch }),
  }),
  patientPortalRedirect: ({ params, mrnSearch }) => ({
    to: getRoute(
      'patientPortalRedirect',
      {},
      mapSearchParams(params, { externalRedirect: 'url' }, mrnSearch),
    ),
  }),
  selfCareModule: ({ params, mrnSearch }) => ({
    to: getRoute('module', mapRouteParams(params, { moduleId: 'moduleId' }, {}), { ...mrnSearch }),
  }),
  selfCareSkill: ({ params, mrnSearch }) => ({
    to: getRoute(
      'skill',
      mapRouteParams(params, { moduleId: 'moduleId', skillId: 'skillId' }, { slide: '1' }),
      { ...mrnSearch },
    ),
  }),
  signup: ({ mrnSearch }) => ({
    to: getRoute('signup', {}, { ...mrnSearch }),
  }),
};

type MrnSearch = { mrn: string } | undefined;
type RouteParser = (options: { params: object | undefined; mrnSearch: MrnSearch }) =>
  | {
      to: string;
      toOptions?: NavigateOptions;
    }
  | undefined;

const mapParams = <ParamsMap extends Record<string, string>>(
  params: object | undefined,
  paramsMap: ParamsMap,
): Record<keyof ParamsMap, string> => {
  return chain(paramsMap)
    .mapValues((to, from) => {
      return get<typeof params, typeof from, string>(params, from, '');
    })
    .value();
};

const mapRouteParams = <
  ParamsMap extends Record<string, string>,
  StaticParams extends Record<string, string>,
>(
  params: object | undefined,
  paramsMap: ParamsMap,
  staticParams: StaticParams,
): Record<keyof ParamsMap, string> & StaticParams => {
  const mappedValues = mapParams(params, paramsMap);

  return { ...mappedValues, ...staticParams };
};

const mapSearchParams = <ParamsMap extends Record<string, string>>(
  params: object | undefined,
  paramsMap: ParamsMap,
  mrnSearch: MrnSearch,
): { mrn?: string } & Partial<Record<keyof ParamsMap, string>> => {
  const mappedValues = mapParams(params, paramsMap);

  const valuesWithMrn = { ...mrnSearch, ...mappedValues };

  // Removes any empty strings from the given search params.
  // This is useful for routes that have multiple combinations of possible search params.
  return omitBy<typeof valuesWithMrn>(valuesWithMrn, isEmpty);
};

/**
 * Parses an encoded MRN into a local route.
 */
export const parseMrnIntoRoute = (
  encodedMrn: string,
): { to: string; toOptions: NavigateOptions } => {
  const defaultTo = { to: getRoute('home', {}), toOptions: {} };

  const mrn = parseMrn(encodedMrn);
  if (mrn === undefined) {
    return defaultTo;
  }

  const { nextDestination, remaining } = getNextDestination(mrn);
  if (!nextDestination) {
    return defaultTo;
  }

  const { destination, params } = nextDestination;
  const remainingMrn = remaining?.toString();

  const matchedRoute = destinationToRouteMap[destination]?.({
    params,
    mrnSearch: remainingMrn !== undefined ? { mrn: remainingMrn } : undefined,
  });

  if (!matchedRoute) {
    return defaultTo;
  }

  return {
    to: matchedRoute.to,
    toOptions: matchedRoute.toOptions ?? {},
  };
};
