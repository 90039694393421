import { Button, Layout } from '../../../components/core';
import { useSkillContext } from '../../../contexts';
import { getStylesheet } from '../../../styles';

type SkillContinueButtonProps = {
  isVisible: boolean;
  onContinue?: (triggerNextSlide: () => void) => void;
};

export const SkillContinueButton = ({
  isVisible,
  onContinue,
}: SkillContinueButtonProps): JSX.Element => {
  const { currentSlide, nextSlide, triggerNextSlide } = useSkillContext();

  const isCompletion = currentSlide?.slice_type === 'completion_slide';
  const isBeforeCompletion = nextSlide?.slice_type === 'completion_slide';

  const onContinuePressed = (): void => {
    if (onContinue) {
      onContinue(triggerNextSlide);
    } else {
      triggerNextSlide();
    }
  };

  let buttonLabel = 'Continue';
  let buttonAccessibilityLabel = 'Continue to next slide';
  let buttonTestID = 'button-skill-continue';

  if (isBeforeCompletion) {
    buttonLabel = 'Finish';
    buttonAccessibilityLabel = 'Finish slides';
    buttonTestID = 'button-skill-finish';
  } else if (isCompletion) {
    buttonLabel = 'Up Next';
    buttonAccessibilityLabel = 'Continue to what is up next';
    buttonTestID = 'button-skill-to-up-next';
  }

  return (
    <Layout.Center>
      <Button.skillLarge
        {...styles.button}
        isDisabled={!isVisible}
        accessibilityLabel={buttonAccessibilityLabel}
        testID={buttonTestID}
        onPress={onContinuePressed}
      >
        {buttonLabel}
      </Button.skillLarge>
    </Layout.Center>
  );
};

const styles = getStylesheet({
  button: {
    flexGrow: 1,
    maxWidth: 500,
    width: '100%',
  },
});
