import {
  Badge,
  Button,
  Heading,
  Layout,
  Link,
  Skeleton,
  StatusFeedback,
  Text,
} from '../../../components/core';
import { IconAlertCircle } from '../../../components/icons/IconAlertCircle';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { IconClockFour } from '../../../components/icons/IconClockFour';
import { IconCoffee } from '../../../components/icons/IconCoffee';
import { IconCompass } from '../../../components/icons/IconCompass';
import { IconHeart } from '../../../components/icons/IconHeart';
import { IconHeartHandShake } from '../../../components/icons/IconHeartHandShake';
import { IconHome } from '../../../components/icons/IconHome';
import { IconMoon } from '../../../components/icons/IconMoon';
import { IconNavigation } from '../../../components/icons/IconNavigation';
import { IconPin } from '../../../components/icons/IconPin';
import { IconSprout } from '../../../components/icons/IconSprout';
import { IconTrophy } from '../../../components/icons/IconTrophy';
import { IconZap } from '../../../components/icons/IconZap';
import { PageError } from '../../../components/page';
import { useNavigateOnPortal } from '../../../content/portal/usePortalNavigate';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import {
  CoachingProgramType,
  useConsumeCoachingRecommendationMutation,
  useConsumeTherapyRecommendationMutation,
  useCustomClinicalRecConfigGetForUserQuery,
  useGetHubAssessmentLinkQuery,
} from '../../../graphQL';
import { getRoute, useCurrentRouteName, useExternalNavigate, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { ImageCoachingStudent } from '../images/ImageCoachingStudent';
import { ImageCoachingStudentSquare } from '../images/ImageCoachingStudentSquare';
import { ImageHandPhone } from '../images/ImageHandPhone';
import { ImageHandPhoneSquare } from '../images/ImageHandPhoneSquare';
import { ImageHandPlant } from '../images/ImageHandPlant';
import { ImageHandPlantSquare } from '../images/ImageHandPlantSquare';
import { ImageHandTablet } from '../images/ImageHandTablet';
import { ImageHandTabletSquare } from '../images/ImageHandTabletSquare';
import { ImageStudentLaptop } from '../images/ImageStudentLaptop';
import { ImageStudentLaptopSquare } from '../images/ImageStudentLaptopSquare';
import { ImageStudentWithBackpackFacingAway } from '../images/ImageStudentWithBackpackFacingAway';
import { ImageStudentWithBackpackFacingAwaySquare } from '../images/ImageStudentWithBackpackFacingAwaySquare';

type CareRecommendationCTACardProps = {
  showRecommended?: boolean;
  title: string;
  Image: () => JSX.Element;
  SquareImage: () => JSX.Element;
  caption: string;
  features: JSX.Element[];
  cta: JSX.Element;
  warning?: JSX.Element;
};

export const CareRecommendationCTACard = ({
  showRecommended = false,
  title,
  Image,
  SquareImage,
  caption,
  features,
  cta,
  warning,
}: CareRecommendationCTACardProps): JSX.Element => {
  const [useSquareImage] = useBreakpointSwitch({
    mobile: [true],
    desktop: [false],
  });

  return (
    <Layout.VStack {...styles.careRecommendationCard} space={5}>
      <Layout.Shift {...styles.careRecommendationShift}>
        <Layout.Stack {...styles.CTAImageStyles}>
          {useSquareImage ? <SquareImage /> : <Image />}
        </Layout.Stack>

        <Layout.VStack {...styles.careRecommendationCardContent} space={5}>
          <Layout.VStack space={5}>
            {showRecommended && (
              <Badge.successSubtle {...styles.recommendedBadge}>Recommended</Badge.successSubtle>
            )}
            <Heading.h3>{title}</Heading.h3>
            <Text.paraSmall {...styles.caption}>{caption}</Text.paraSmall>
            <Layout.VStack space={3}>
              {features.map((feature, index) => {
                return (
                  <Layout.HStack key={index} space={3}>
                    {feature}
                  </Layout.HStack>
                );
              })}
            </Layout.VStack>
          </Layout.VStack>

          <Layout.VStack space={2}>{cta}</Layout.VStack>
        </Layout.VStack>
      </Layout.Shift>

      {warning ?? null}
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  CTAImageStyles: {
    alignSelf: 'flex-start',
    borderRadius: 8,
    overflow: 'hidden',
  },

  careRecommendationCard: {
    backgroundColor: 'secondary.50',
    borderRadius: 4,
    padding: 6,
    shadow: 'large',
  },

  careRecommendationShift: {
    gap: 9,
  },

  careRecommendationCardContent: {
    flex: 1,
    flexGrow: 1,
  },

  careRecommendationCTAButton: {
    alignSelf: 'flex-start',
  },

  caption: {
    maxWidth: '95%',
  },

  recommendedBadge: {
    alignSelf: 'flex-start',
  },
});

export const TherapyCTACard = ({
  isUserDeactivated,
}: {
  isUserDeactivated: boolean;
}): JSX.Element => {
  const [consumeTherapyRecommendation, { loading }] = useConsumeTherapyRecommendationMutation();

  const navigateOnPortal = useNavigateOnPortal();

  const onTherapyCTAClick = async (): Promise<void> => {
    void consumeTherapyRecommendation({
      onCompleted: ({ consumeTherapyRecommendation: response }) => {
        if (response) {
          navigateOnPortal(getRoute('patientPortal', {}));
        }
      },
    });
  };

  const callToAction = !isUserDeactivated ? (
    <Button.primaryMedium
      {...styles.careRecommendationCTAButton}
      aria-label="Choose a therapist (opens in new tab)"
      testID="button-therapy-recommendation-cta"
      onPress={onTherapyCTAClick}
      isLoading={loading}
    >
      Choose a therapist
    </Button.primaryMedium>
  ) : (
    <></>
  );

  const eligibilityWarning = isUserDeactivated ? (
    <StatusFeedback.warning Icon={IconAlertCircle} testID="therapy-rec-eligibility-warning">
      <Text.para>
        Due to the termination of your previous Mantra care plan, we’ll need to verify your
        eligibility to access care. Please email our care navigation team at{' '}
        <Link.para bold to="mailto:hi@mantrahealth.com">
          hi@mantrahealth.com
        </Link.para>{' '}
        to see if you are eligible to continue with therapy.
      </Text.para>
    </StatusFeedback.warning>
  ) : undefined;

  return (
    <CareRecommendationCTACard
      showRecommended={true}
      title="Individual Therapy"
      Image={ImageHandPlant}
      SquareImage={ImageHandPlantSquare}
      caption="Schedule an initial visit to get started with care. Select from a list of specialized providers and pick a date and time that works best for you."
      features={[
        <>
          <IconHome size={4} accessibilityHidden />
          <Text.caption>Virtual care from the comfort of your home</Text.caption>
        </>,
        <>
          <IconHeart size={4} accessibilityHidden />
          <Text.caption>Meet your needs and preferences</Text.caption>
        </>,
        <>
          <IconMoon size={4} accessibilityHidden />
          <Text.caption>Available nights and weekends</Text.caption>
        </>,
      ]}
      cta={callToAction}
      warning={eligibilityWarning}
      key="therapy-cta-card"
    />
  );
};

/**
 * Internal terminology uses ExecutiveFunctioning
 * UI displays to students "Productivity Coaching"
 */
export const ExecutiveFunctionCoachingCTACard = (): JSX.Element => {
  const [consumeCoachingRecommendation, { loading }] = useConsumeCoachingRecommendationMutation();

  const navigateOnPortal = useNavigateOnPortal();
  const currentRoute = useCurrentRouteName();

  const onCoachingCTAClick = async (): Promise<void> => {
    await consumeCoachingRecommendation({
      variables: { coachingProgramType: CoachingProgramType.ExecutiveFunctioning },
      onCompleted: response => {
        if (response.consumeCoachingRecommendation) {
          navigateOnPortal(getRoute('coachingOnboarding', {}), { state: { from: currentRoute } });
        }
      },
    });
  };

  return (
    <CareRecommendationCTACard
      title="Productivity Coaching"
      Image={ImageStudentLaptop}
      SquareImage={ImageStudentLaptopSquare}
      caption="Get one-on-one support and accountability to help you plan, organize, and manage your time effectively. Your coach will help you create a personalized plan and teach you evidence-based tools for success in school and your career."
      features={[
        <>
          <IconHeartHandShake size={4} accessibilityHidden />
          <Text.caption>One-on-one support and accountability</Text.caption>
        </>,
        <>
          <IconClockFour size={4} accessibilityHidden />
          <Text.caption>Learn skills for organization and time management</Text.caption>
        </>,
        <>
          <IconZap size={4} accessibilityHidden />
          <Text.caption>Great for people with ADHD and attention issues</Text.caption>
        </>,
      ]}
      cta={
        <Button.primaryMedium
          {...styles.careRecommendationCTAButton}
          testID="button-coaching-executive-functioning-recommendation-cta"
          onPress={onCoachingCTAClick}
          isLoading={loading}
        >
          Get Started
        </Button.primaryMedium>
      }
      key="coaching-cta-card"
    />
  );
};

export const EmotionalWellnessCoachingCTACard = (): JSX.Element => {
  const [consumeCoachingRecommendation, { loading }] = useConsumeCoachingRecommendationMutation();

  const navigateOnPortal = useNavigateOnPortal();
  const currentRoute = useCurrentRouteName();

  const onCoachingCTAClick = async (): Promise<void> => {
    await consumeCoachingRecommendation({
      variables: { coachingProgramType: CoachingProgramType.EmotionalWellness },
      onCompleted: response => {
        if (response.consumeCoachingRecommendation) {
          navigateOnPortal(getRoute('coachingOnboarding', {}), { state: { from: currentRoute } });
        }
      },
    });
  };

  return (
    <CareRecommendationCTACard
      title="Emotional Wellness Coaching"
      Image={ImageCoachingStudent}
      SquareImage={ImageCoachingStudentSquare}
      caption="Connect with a coach who can help you achieve your wellness goals. Your coach will collaborate with you to create a personalized plan to help reach your individual goals around wellness topics such as anxiety, confidence and communication."
      features={[
        <>
          <IconHeartHandShake size={4} accessibilityHidden />
          <Text.caption>Diverse, professional coaches</Text.caption>
        </>,
        <>
          <IconTrophy size={4} accessibilityHidden />
          <Text.caption>Tackle your wellness goals</Text.caption>
        </>,
        <>
          <IconClockFour size={4} accessibilityHidden />
          <Text.caption>24/7 access to evidence-based content</Text.caption>
        </>,
      ]}
      cta={
        <Button.primaryMedium
          {...styles.careRecommendationCTAButton}
          testID="button-coaching-emotional-wellness-recommendation-cta"
          onPress={onCoachingCTAClick}
          isLoading={loading}
        >
          Get Started
        </Button.primaryMedium>
      }
      key="coaching-cta-card"
    />
  );
};

export const SelfCareCTACard = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <CareRecommendationCTACard
      title="Self Care"
      Image={ImageHandPhone}
      SquareImage={ImageHandPhoneSquare}
      caption="Build essential life skills that can help you thrive on and off campus. This self-guided, evidence-based program was created specifically for college students and will help you take charge of your well-being and teach you how to cope, problem-solve, and communicate."
      features={[
        <>
          <IconClockFour size={4} accessibilityHidden />
          <Text.caption>24/7 access to all content</Text.caption>
        </>,
        <>
          <IconNavigation size={4} accessibilityHidden />
          <Text.caption>Complete at your own pace</Text.caption>
        </>,
        <>
          <IconSprout size={4} accessibilityHidden />
          <Text.caption>Short, dynamic micro-lessons</Text.caption>
        </>,
      ]}
      cta={
        <Button.primaryMedium
          {...styles.careRecommendationCTAButton}
          testID="button-self-care-recommendation-cta"
          onPress={() => navigate(getRoute('selfCareHome', {}))}
        >
          Explore Self Care
        </Button.primaryMedium>
      }
      key="self-care-cta-card"
    />
  );
};

export const AssessmentVisitCTACard = (): JSX.Element | null => {
  const externalNavigate = useExternalNavigate();

  const { data: linkData, error, refetch } = useGetHubAssessmentLinkQuery();

  if (!linkData || error) {
    return <PageError onRefreshPress={refetch} />;
  }

  return (
    <CareRecommendationCTACard
      showRecommended={true}
      title="Schedule an assessment visit"
      Image={ImageHandTablet}
      SquareImage={ImageHandTabletSquare}
      caption="Book a short meeting with a member of our team to further discuss your needs and we'll ensure you're connected to the right level of care."
      features={[
        <>
          <IconCoffee size={4} accessibilityHidden />
          <Text.caption>Meet with a professional</Text.caption>
        </>,
        <>
          <IconPin size={4} accessibilityHidden />
          <Text.caption>Discuss your needs</Text.caption>
        </>,
        <>
          <IconCompass size={4} accessibilityHidden />
          <Text.caption>Connect with the right care</Text.caption>
        </>,
      ]}
      cta={
        <Button.primaryMedium
          {...styles.careRecommendationCTAButton}
          testID="button-assessment-visit-recommendation-cta"
          onPress={() => externalNavigate(linkData.hubGetAssessmentLink)}
        >
          Schedule Assessment Visit
        </Button.primaryMedium>
      }
      key="assessment-visit-cta-card"
    />
  );
};

export const CustomClinicalRecCTACard = (): JSX.Element => {
  const externalNavigate = useExternalNavigate();
  const [verticalFeedback] = useBreakpointSwitch({
    mobile: [true],
    desktop: [false],
  });
  const {
    data: customClinicalEntitlementConfigurationData,
    error: configError,
    refetch,
  } = useCustomClinicalRecConfigGetForUserQuery({});
  if (configError !== undefined) {
    return (
      <StatusFeedback.danger
        Icon={IconAlertTriangle}
        testID="custom-clinical-rec-load-error-status-feedback"
        buttonText="Reload page"
        vertical={verticalFeedback}
        onPress={() => {
          void refetch();
        }}
      >
        We're unable to load your recommendation at this time. Try refreshing?
      </StatusFeedback.danger>
    );
  }
  if (!customClinicalEntitlementConfigurationData) {
    return <Skeleton />;
  }

  const {
    customClinicalRecTitle,
    customClinicalRecDescription,
    customClinicalRecButtonText,
    customClinicalRecButtonLink,
  } = customClinicalEntitlementConfigurationData.customClinicalRecConfigGetForUser;

  const showCTA = customClinicalRecButtonText != null && customClinicalRecButtonLink != null;

  return (
    <CareRecommendationCTACard
      title={customClinicalRecTitle}
      Image={ImageStudentWithBackpackFacingAway}
      SquareImage={ImageStudentWithBackpackFacingAwaySquare}
      caption={customClinicalRecDescription}
      showRecommended={true}
      features={[]}
      cta={
        <>
          {showCTA && (
            <Button.primaryMedium
              {...styles.careRecommendationCTAButton}
              testID="button-custom-clinical-recommendation-cta"
              onPress={() => externalNavigate(customClinicalRecButtonLink)}
            >
              {customClinicalRecButtonText}
            </Button.primaryMedium>
          )}
        </>
      }
      key="custom-clinical-rec-cta-card"
    />
  );
};
