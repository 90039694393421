import { useEffect } from 'react';
import { RouteKeys, getRoute, useCurrentRouteName, useNavigate } from '../../../routes';

const onDemandRouteNames: readonly RouteKeys[] = [
  'onDemandHome',
  'onDemandConfirmInfo',
  'onDemandJoinCall',
  'onDemandPreEncounterForm',
] as const;

export const useRedirectFromOnDemand = (): void => {
  const navigate = useNavigate();
  const currentRouteName = useCurrentRouteName();

  useEffect(() => {
    if (onDemandRouteNames.includes(currentRouteName)) {
      navigate(getRoute('connectNowHome', {}), { replace: true });
    }
  }, [navigate, currentRouteName]);
};
