import * as WebBrowser from 'expo-web-browser';
import { useState } from 'react';
import { Platform } from 'react-native';
import { Button, Heading, Layout, Link, Text } from '../../../components/core';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import { IconVideo } from '../../../components/icons/IconVideo';
import { useNavigateFromHubMobileToWebLazyQuery } from '../../../graphQL';
import { getRoute, useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { isDuringHoliday } from '../utils/availability';
import { BrowserIncompatibilityWarning } from './BrowserIncompatibilityWarning';
import { InterpretationSupportModal } from './InterpretationSupportModal';

type VideoContactProps = {
  crisisPhoneNumber: string;
  isDuringServiceHours: boolean;
  interpretationServicesSMS?: string;
};

export const VideoContact = (props: VideoContactProps): JSX.Element => {
  if (isDuringHoliday()) {
    return <DuringHoliday {...props} />;
  } else if (!props.isDuringServiceHours) {
    return <AfterServiceHours {...props} />;
  }

  return <DuringServiceHours {...props} />;
};

const CallToAction = ({
  message,
  crisisPhoneNumber,
}: {
  message: string;
  crisisPhoneNumber: string;
}): JSX.Element => (
  <Layout.VStack {...styles.warningBox} space={4}>
    <Heading.h6 center>{message}</Heading.h6>
    {crisisPhoneNumber && (
      <Text.caption center>
        If you need immediate crisis support, call or text{' '}
        <Link.para bold to={`tel:${crisisPhoneNumber}`}>
          {crisisPhoneNumber}
        </Link.para>
      </Text.caption>
    )}
  </Layout.VStack>
);

const VideoCallLayout = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Layout.VStack {...styles.videoCall} space={2}>
    <Heading.h5>ConnectNow via Video</Heading.h5>
    <Layout.VStack space={4}>{children}</Layout.VStack>
  </Layout.VStack>
);

const DuringServiceHours = ({
  crisisPhoneNumber,
  interpretationServicesSMS,
}: VideoContactProps): JSX.Element => {
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  const [showInterpretationModal, setShowInterpretationModal] = useState(false);
  const externalNavigate = useExternalNavigate();
  const [queryForHubWebLoginUrl] = useNavigateFromHubMobileToWebLazyQuery();

  const onVideoCallClick = async (): Promise<void> => {
    if (Platform.OS === 'web') {
      externalNavigate(getRoute('connectNowConfirmInfo', {}));
    } else if (Platform.OS === 'android') {
      setShowBrowserWarning(true);
    } else {
      await openBrowser();
    }
  };

  const openBrowser = async (): Promise<void> => {
    const { data } = await queryForHubWebLoginUrl({ variables: { source: 'connectNow' } });
    const hubWebLoginUrl = data?.navigateFromHubMobileToWeb;
    if (hubWebLoginUrl !== undefined) {
      void WebBrowser.openBrowserAsync(hubWebLoginUrl);
    }
  };

  const usesInterpretationServices = interpretationServicesSMS !== undefined;

  return (
    <>
      <VideoCallLayout>
        <Text.caption>Available everyday from noon - 12 am ET*</Text.caption>

        <Text.paraSmall>Get connected in 10 minutes or less.</Text.paraSmall>

        <Button.primaryLarge
          testID="button-connect-now-video-call"
          onPress={onVideoCallClick}
          leftIcon={<IconVideo accessibilityLabel="Video" />}
        >
          Get Connected via Video
        </Button.primaryLarge>

        {usesInterpretationServices && (
          <Button.tertiaryMedium
            aria-label="Open Interpretation/ASL Services Instructions"
            testID="button-open-interpretation-services"
            onPress={() => setShowInterpretationModal(true)}
            rightIcon={<IconChevronRight size={5} aria-hidden />}
          >
            Need Interpretation/ASL Services?
          </Button.tertiaryMedium>
        )}
      </VideoCallLayout>

      {showBrowserWarning && (
        <BrowserIncompatibilityWarning
          crisisPhoneNumber={crisisPhoneNumber}
          onContinue={openBrowser}
          onClose={() => setShowBrowserWarning(false)}
        />
      )}

      {usesInterpretationServices && showInterpretationModal && (
        <InterpretationSupportModal
          onClose={() => setShowInterpretationModal(false)}
          interpretationServicesSMS={interpretationServicesSMS}
        />
      )}
    </>
  );
};

const AfterServiceHours = ({ crisisPhoneNumber }: { crisisPhoneNumber: string }): JSX.Element => (
  <VideoCallLayout>
    <Text.caption>Available every day from noon - 12am ET*</Text.caption>
    <CallToAction
      message="ConnectNow video support is currently offline and will return at noon ET."
      crisisPhoneNumber={crisisPhoneNumber}
    />
  </VideoCallLayout>
);

const DuringHoliday = ({ crisisPhoneNumber }: { crisisPhoneNumber: string }): JSX.Element => (
  <VideoCallLayout>
    <Text.caption>Available every day from noon - 12am ET*</Text.caption>
    <CallToAction
      message="ConnectNow video support is currently offline for a scheduled holiday."
      crisisPhoneNumber={crisisPhoneNumber}
    />
  </VideoCallLayout>
);

const styles = getStylesheet({
  videoCall: {
    backgroundColor: 'white',
    borderRadius: 8,
    flex: 1,
    padding: 6,
    shadow: 'large',
  },

  warningBox: {
    alignItems: 'center',
    backgroundColor: 'warning.50',
    borderColor: 'warning.300',
    borderRadius: 4,
    borderWidth: 1,
    padding: 5,
  },
});
