import { registerRootComponent } from 'expo';
import { useEffect } from 'react';
import { AppPlugins } from './AppPlugins';
import { useSetup } from './plugins';
import { Router } from './routes';
import Sentry, { logStartup } from './utils/sentry';

export default function App(): JSX.Element | null {
  const { isReady } = useSetup();

  useEffect(() => {
    logStartup('App component mounted');
    return () => logStartup('App component unmounted');
  }, []);

  useEffect(() => {
    logStartup('Ready state changed', { isReady });
  }, [isReady]);

  if (!isReady) {
    logStartup('App not ready - returning null');
    return null;
  }

  logStartup('Rendering main app components');
  return (
    <Router>
      <AppPlugins />
    </Router>
  );
}

registerRootComponent(Sentry.wrap(App));
