import { JSX, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLoading } from '../../components/page';
import { useTherapyContext } from '../../contexts';
import { getRoute, useCurrentRouteSearch } from '../../routes';
import { useTitle } from '../../utils/useTitle';

export const PatientPortalPage = (): JSX.Element => {
  useTitle('Redirecting to patient portal');

  const navigate = useNavigate();
  const searchParams = useCurrentRouteSearch();
  const { hasLoaded, navigateToPatientPortal } = useTherapyContext();

  const [hasOpened, setHasOpened] = useState(false);

  useEffect(() => {
    if (hasLoaded) {
      navigateToPatientPortal({
        mrn: searchParams.mrn,
        onCompleted: () => {
          setHasOpened(true);
        },
        onError: () => {
          setHasOpened(true);
        },
      });
    }
  }, [hasLoaded]);

  useEffect(() => {
    // After the patient portal opens in a new tab, route to the home page.
    if (hasOpened) {
      navigate(getRoute('home', {}));
    }
  }, [hasOpened]);

  return <PageLoading pageName="patient portal" />;
};
