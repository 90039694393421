import React from 'react';
import { Button, Heading, Layout, SimpleModal, StatusFeedback, Text } from '../../components/core';
import { IconAlertCircle } from '../../components/icons/IconAlertCircle';
import { IconAlertTriangle } from '../../components/icons/IconAlertTriangle';
import { IconExternalLink } from '../../components/icons/IconExternalLink';
import { getStylesheet } from '../../styles';

type FindCareCrisisSupportModalProps = {
  onContinue: () => void;
  onPress: () => void;
};

export const FindCareCrisisSupportModal = ({
  onContinue,
  onPress,
}: FindCareCrisisSupportModalProps): JSX.Element => {
  const headerId = 'find-care-crisis-support-modal-header';

  return (
    <SimpleModal headerId={headerId} onClose={onContinue} paddingTop={12} size="lg">
      <Layout.VStack padding={2} space={8}>
        <Layout.VStack space={4}>
          <IconAlertTriangle aria-hidden size={6} />

          <Layout.Flex {...styles.headerSwap}>
            <Heading.h2 id={headerId} level={1}>
              Are you in need of immediate crisis support?
            </Heading.h2>

            <Heading.h5>BEFORE CONTINUING...</Heading.h5>
          </Layout.Flex>

          <Text.para>
            For your safety, we want to pause this assessment to offer you in-the-moment support. If
            you don't feel this applies to you or your situation, please continue the assessment.
          </Text.para>
        </Layout.VStack>

        <StatusFeedback.warning
          Icon={IconAlertCircle}
          testID="find-care-crisis-support-assessment-warning"
          vertical
        >
          <Text.para bold>
            Note: Your assessment progress will not be saved if you leave to access ConnectNow
          </Text.para>
        </StatusFeedback.warning>

        <Layout.VStack space={4}>
          <Button.dangerLarge
            isLink
            testID="button-visit-connect-now-resources"
            onPress={onPress}
            rightIcon={<IconExternalLink aria-hidden />}
          >
            Get support now
          </Button.dangerLarge>

          <Button.secondaryLarge testID="button-continue-assessment" onPress={onContinue}>
            Continue assessment
          </Button.secondaryLarge>
        </Layout.VStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  headerSwap: {
    flexDirection: 'column-reverse',
    gap: 4,
  },
});
