import { JSX, useEffect } from 'react';
import { Button, Layout } from '../../components/core';
import { PageContent, PageCrisis } from '../../components/page';
import { Block, usePortalContext } from '../../contexts/portalContext';
import { useScrollContext } from '../../contexts/scrollContext';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { useTitle } from '../../utils/useTitle';
import { AgeBlocked } from './components/AgeBlocked';
import { AgeConsentRequired } from './components/AgeConsentRequired';
import { AgeTermsRequired } from './components/AgeTermsRequired';
import { GenericBlocked } from './components/GenericBlocked';
import { GeolocationBlocked } from './components/GeolocationBlocked';

// Tuples of [BlockedComponent, showLogoutButton]
const blockedMap: Record<Block | 'default', [() => JSX.Element, boolean, string]> = {
  ageConsentRequired: [AgeConsentRequired, true, 'Error: Unable to Continue'],
  ageTermsRequired: [AgeTermsRequired, true, 'Accept Terms'],
  ageTooYoung: [AgeBlocked, true, 'Error: Unable to Continue'],
  default: [GenericBlocked, true, 'Error: Unable to Continue'],
  locationRestricted: [GeolocationBlocked, false, 'Error: Unable to Continue'],
};

export const BlockedPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { activeBlock } = usePortalContext();
  const { scrollToTop } = useScrollContext();

  const [BlockedComponent, showLogout, title] =
    blockedMap[activeBlock ?? 'default'] ?? blockedMap.default;

  useTitle(title);

  useEffect(() => {
    scrollToTop();
  }, []);

  const onLogoutPress = (): void => {
    navigate(getRoute('logout', {}));
  };

  return (
    <PageContent {...styles.page} pageSize="medium" testID="blocked-page">
      <Layout.VStack space={8}>
        <BlockedComponent />

        <Layout.VStack space={4}>
          <PageCrisis headingLevel={2} />

          {showLogout && (
            <Button.tertiaryMedium
              isLink
              alignSelf="center"
              onPress={onLogoutPress}
              testID="button-age-blocked-logout"
            >
              Logout
            </Button.tertiaryMedium>
          )}
        </Layout.VStack>
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    height: '100%',
  },
});
