import React from 'react';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import { getRoute, useExternalNavigate, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { ImageHandBallSquare } from '../images/ImageHandBallSquare';
import { ImageHandPhoneSquare } from '../images/ImageHandPhoneSquare';
import { ImageTogetherallSquare } from '../images/ImageTogetherallSquare';

type SelfServiceOptionProps = {
  Image: () => JSX.Element;
  title: string;
  caption: string;
  cta: JSX.Element;
};

export const SelfServiceOption = ({
  Image,
  title,
  caption,
  cta,
}: SelfServiceOptionProps): JSX.Element => {
  return (
    <Layout.HStack {...styles.container} space={5}>
      <Layout.View {...styles.selfServiceOptionImage}>
        <Image />
      </Layout.View>
      <Layout.VStack {...styles.selfServiceContent} space={1}>
        <Heading.h4>{title}</Heading.h4>
        <Text.paraSmall>{caption}</Text.paraSmall>
        <Layout.VStack space={2}>{cta}</Layout.VStack>
      </Layout.VStack>
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  container: {
    flex: 1,
  },

  selfServiceContent: {
    flex: 1,
  },

  selfServiceCTA: {
    justifyContent: 'flex-start',
    paddingLeft: 0,
  },

  selfServiceOptionImage: {
    alignSelf: 'flex-start',
    borderRadius: '8px',
    overflow: 'hidden',
  },
});

export const TogetherAllCTACard = (): JSX.Element => {
  const externalNavigate = useExternalNavigate();
  return (
    <SelfServiceOption
      Image={ImageTogetherallSquare}
      title="Online Peer Support"
      caption="Check out Togetherall, a 24/7, anonymous, online peer-to-peer mental health support community."
      cta={
        <Button.tertiarySmall
          {...styles.selfServiceCTA}
          accessibilityLabel="Visit Togetherall"
          rightIcon={<IconChevronRight size={4} />}
          onPress={() => externalNavigate('https://account.v2.togetherall.com/register/student')}
          testID="button-visit-togetherall"
        >
          Visit Togetherall
        </Button.tertiarySmall>
      }
    />
  );
};

export const ConnectNowCTACard = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <SelfServiceOption
      Image={ImageHandBallSquare}
      title="ConnectNow"
      caption="Get connected with a mental health staff member who is trained to support you in a high-stress situation with sensitivity and expertise."
      cta={
        <Button.tertiarySmall
          {...styles.selfServiceCTA}
          accessibilityLabel="Visit ConnectNow"
          rightIcon={<IconChevronRight size={4} />}
          onPress={() => navigate(getRoute('connectNowHome', {}))}
          testID="button-visit-connectnow"
        >
          Visit ConnectNow
        </Button.tertiarySmall>
      }
    />
  );
};

export const SelfCareSelfServiceCTACard = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <SelfServiceOption
      Image={ImageHandPhoneSquare}
      title="Self Care"
      caption="A self-guided program created specifically for college students to help you learn how to cope, problem-solve, and communicate."
      cta={
        <Button.tertiarySmall
          {...styles.selfServiceCTA}
          accessibilityLabel="Visit Self Care"
          rightIcon={<IconChevronRight size={4} />}
          onPress={() => navigate(getRoute('selfCareHome', {}))}
          testID="button-visit-selfcare"
        >
          Visit Self Care
        </Button.tertiarySmall>
      }
    />
  );
};
