import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Layout, Link } from '../../../components/core';
import { FormErrorSubmit, FormInput, FormInputPassword } from '../../../components/form';
import { getRoute } from '../../../routes';
import { LoginRateLimitError } from '../components/LoginRateLimitError';
import { useLoginRedirect } from '../hooks/useLoginRedirect';
import { ErrorTypesLoginUser, useLoginUser } from '../hooks/useLoginUser';
import { useSsoNavigation } from '../hooks/useSsoNavigation';

export const LoginForm = ({
  onDisabled,
  mrn,
}: {
  onDisabled: () => void;
  mrn: string | undefined;
}): JSX.Element => {
  const loginRedirect = useLoginRedirect();
  const navigate = useNavigate();
  const ssoNavigate = useSsoNavigation({
    onError: ssoNavError => {
      formContext.setError('submit', ssoNavError);
    },
  });

  const [showRateLimitError, setShowRateLimitError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formContext = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const [loginUser, { loading }] = useLoginUser({
    onCompleted: (loginResponse): void => {
      const responseType = loginResponse.__typename;

      if (responseType === 'PreLoginOrPasswordLoginSaml') {
        ssoNavigate(loginResponse.samlEntryPoint);
        return;
      }

      if (responseType === 'PreLoginOrPasswordLoginRedirect') {
        navigate(
          getRoute(
            'patientPortalRedirect',
            {},
            { external_redirect: loginResponse.externalRedirect },
          ),
        );
        return;
      }

      if (responseType === 'PreLoginOrPasswordLoginSuccess') {
        void loginRedirect();
        return;
      }

      setShowPassword(true);
    },
    onError: authError => {
      if (authError.type === ErrorTypesLoginUser.RateLimit) {
        setShowRateLimitError(true);
        return;
      }
      if (authError.type === ErrorTypesLoginUser.Deactivated) {
        onDisabled();
        return;
      }

      formContext.setError('submit', authError);
    },
  });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async ({ email, password }): Promise<void> => {
      await loginUser({ email, password: password || undefined, mrn: mrn ?? undefined });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.VStack space={3}>
          <FormInput
            testID="login-input-email"
            label="Email address"
            name="email"
            placeholder="School email address"
            autoComplete="email"
            autoCapitalize="none"
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            inputMode="email"
            keyboardType="email-address"
            onSubmitEditing={!showPassword ? submitForm : undefined}
          />

          {showPassword && (
            <FormInputPassword
              testID="login-input-password"
              label="Password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              autoFocus
              isRequired
              control={control}
              error={errors.password}
              onSubmitEditing={submitForm}
            />
          )}

          {showPassword && (
            <Link.paraSmall bold to={getRoute('loginResetPassword', {})}>
              Forgot Password?
            </Link.paraSmall>
          )}

          <Layout.VStack aria-live="polite" space={3}>
            {showRateLimitError && <LoginRateLimitError />}

            {errors.submit && (
              <FormErrorSubmit marginTop={3}>{errors.submit.message}</FormErrorSubmit>
            )}
          </Layout.VStack>
        </Layout.VStack>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isLink
            isDisabled={loading}
            testID="button-sso-login-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
