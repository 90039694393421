import { useState } from 'react';
import { StatusFeedback, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { ConfirmLeaveModal } from '../../../components/modals/ConfirmLeaveModal';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { getRoute } from '../../../routes';
import { Portals } from '../../portal/portals';
import { usePortalNavigate } from '../../portal/usePortalNavigate';

export const InputMonitorWarning = ({ isVisible }: { isVisible: boolean }): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const portalNavigate = usePortalNavigate();

  const [verticalFeedback] = useBreakpointSwitch({
    mobile: [true],
    desktop: [false],
  });

  const onConfirm = (): void => {
    portalNavigate(
      Portals.ConnectNow,
      getRoute('connectNowHome', {}),
      getRoute('crisisResources', {}),
    );
  };

  return (
    <>
      <StatusFeedback.warning
        isDisabled={!isVisible}
        testID="input-monitor-warning"
        Icon={IconAlertTriangle}
        buttonText="Visit ConnectNow"
        onPress={() => setShowModal(true)}
        vertical={verticalFeedback}
      >
        <Text.paraSmall bold>Please note:</Text.paraSmall> Answers to this form are not monitored in
        real time. If you need immediate support, use ConnectNow or a provided crisis resource.
      </StatusFeedback.warning>

      {showModal && <ConfirmLeaveModal onClose={() => setShowModal(false)} onConfirm={onConfirm} />}
    </>
  );
};
