import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import { JSX, ReactElement } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { TextArea, TextAreaProps } from '../core/Input';
import { FormErrorInput } from './FormErrorInput';
import { FormHelperText } from './FormHelperText';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum InputErrorTypes {
  Pattern = 'pattern', // from rules.pattern
  Required = 'required', // from isRequired or rules.required
}

type FormTextAreaProps<TFieldValues extends FieldValues> = TextAreaProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  Omit<FormLabelProps, 'inputId'> & {
    error?: FieldErrors<TFieldValues>[string];
    helperText?: string | ReactElement;
    hideLabel?: boolean;
  };

export function FormTextArea<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  helperText = '',
  hideLabel = false,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...textAreaProps
}: FormTextAreaProps<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(textAreaProps.isRequired),
      message: 'This field is required.',
    }),
  };

  const nativeId = name;
  const textAreaId = `${nativeId}-textarea`;

  const ariaLabel = label ? { accessibilityLabel: label } : {};

  return (
    <FormControl isInvalid={Boolean(error?.type)} nativeID={nativeId} onLayout={onLayout}>
      {!hideLabel && (
        <FormLabel
          inputId={textAreaId}
          label={label}
          necessityIndicator={necessityIndicator}
          isRequired={textAreaProps.isRequired}
        />
      )}

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextArea
            autoCompleteType={undefined}
            fontSize="md"
            {...textAreaProps}
            {...ariaLabel}
            isInvalid={textAreaProps.isInvalid ?? Boolean(error)}
            onBlur={onBlur}
            onChangeText={onChange}
            placeholderTextColor="secondary.700"
            value={value}
            _web={{
              id: textAreaId,
            }}
          />
        )}
      />

      {helperText !== undefined && <FormHelperText>{helperText}</FormHelperText>}

      {error && <FormErrorInput nativeID={nativeId}>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
