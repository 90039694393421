import { JSX, useEffect } from 'react';
import { Heading, Layout, Text } from '../../components/core';
import { PageContent, PageFooter, PageLoading } from '../../components/page';
import { Portals } from '../../content/portal/portals';
import { usePortalNavigate } from '../../content/portal/usePortalNavigate';
import { useOnboardingUserPhoneQuery } from '../../graphQL';
import { getRoute } from '../../routes';
import { getStylesheet } from '../../styles';
import { useTitle } from '../../utils/useTitle';
import { PhoneNumberForm } from './forms/PhoneNumberForm';
import { ImagePhoneInHand } from './ImagePhoneInHand';

export const OnboardingPhoneNumberPage = (): JSX.Element => {
  useTitle(['Get Notified', 'Welcome']);

  const portalNavigate = usePortalNavigate();
  const { data: phoneData, loading } = useOnboardingUserPhoneQuery();

  const onContinue = ({ replace = false }: { replace?: boolean } = {}): void => {
    portalNavigate(Portals.Loca, getRoute('tour', { slide: '1' }), getRoute('home', {}), {
      replace,
    });
  };

  const phoneNumber = phoneData?.onboardedHubUser.phone ?? '';

  useEffect(() => {
    if (phoneNumber !== '') {
      // If they already have a phone number in the database, then skip this since they
      // can't opt out from this screen.
      onContinue({ replace: true });
    }
  }, [phoneNumber]);

  if (loading || phoneNumber !== '') {
    return <PageLoading pageName="user phone data" />;
  }

  return (
    <PageContent {...styles.page} pageSize="medium" testID="onboarding-phone-page">
      <Layout.VStack space={6}>
        <Layout.VStack {...styles.stack} space={4}>
          <ImagePhoneInHand size={181} />

          <Layout.VStack space={8}>
            <Heading.h2 center>Get real-time updates.</Heading.h2>

            <Text.para center>
              Opt in to get appointment reminders and care status updates via SMS.
            </Text.para>
          </Layout.VStack>
        </Layout.VStack>

        <PhoneNumberForm onContinue={onContinue} />
      </Layout.VStack>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    height: '100%',
    marginTop: 16,
  },
  stack: {
    alignItems: 'center',
    width: '100%',
  },
});
