import { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Layout, Text } from '../../../components/core';
import { getRoute } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { OfAgeForm } from '../../onboarding/forms/OfAgeForm';
import { ImageCake } from '../images/ImageCake';

export const AgeTermsRequired = (): JSX.Element => {
  const navigate = useNavigate();

  const onSaved = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <Layout.VStack space={6}>
      <Layout.VStack {...styles.textStack} space={4}>
        <Layout.Flex alignSelf="center">
          <ImageCake />
        </Layout.Flex>

        <Heading.h3 center level={1}>
          Happy (belated) birthday!
        </Heading.h3>

        <Text.para center>
          Now that you’re 18, please agree to Mantra’s Terms and Conditions to proceed.
        </Text.para>
      </Layout.VStack>

      <OfAgeForm onSaved={onSaved} />
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  textStack: {
    alignItems: 'stretch',
  },
});
