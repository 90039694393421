import { JSX } from 'react';
import { Button, Heading, Layout, Link, Text } from '../../components/core';
import { PageContent, PageCrisis } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { useTitle } from '../../utils/useTitle';

export const TherapyEligibilityPage = (): JSX.Element => {
  useTitle('Therapy Eligibility');

  const navigate = useNavigate();

  const onReturnHomePress = (): void => {
    navigate(getRoute('home', {}));
  };

  return (
    <PageContent testID="therapy-eligibility-page">
      <Layout.VStack space={7}>
        <Layout.VStack space={5}>
          <Heading.h3>
            Due to the termination of your previous Mantra care plan, we'll need to verify your
            eligibility to access therapy
          </Heading.h3>

          <Text.para>
            Please email our Care Navigation team at{' '}
            <Link.para to="mailto:hi@mantrahealth.com">hi@mantrahealth.com</Link.para> to see if you
            are eligible to continue with therapy.
          </Text.para>

          <Button.secondaryLarge
            testID="therapy-eligibility-return-home-button"
            onPress={onReturnHomePress}
          >
            Return to Care Hub
          </Button.secondaryLarge>
        </Layout.VStack>

        <PageCrisis />
      </Layout.VStack>
    </PageContent>
  );
};
