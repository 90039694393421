import { getRoute, useNavigate } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { ImagePlant } from './components/ImagePlant';
import { InterstitialSection } from './components/InterstitialSection';
import { useFindCareRedirect } from './hooks/useFindCareRedirect';

export const FindCarePreTriagePage = (): JSX.Element => {
  useTitle(['In Progress', 'Find Care']);

  useFindCareRedirect();

  const navigate = useNavigate();

  return (
    <FindCarePageWrapper testID="find-care-pre-triage-page">
      <InterstitialSection
        imageComponent={<ImagePlant aria-hidden size={194} />}
        header="Now let's talk more about why you are seeking care"
        headerStyles={{ textAlign: 'center' }}
        buttonLabel="Continue"
        buttonAction={() => {
          navigate(
            getRoute('findCareAssessments', {
              assessmentType: 'mental-health-history',
              slide: '1',
            }),
          );
        }}
      />
    </FindCarePageWrapper>
  );
};
