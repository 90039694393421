import React from 'react';
import { Heading, Layout } from '../../components/core';
import { PageError, PageFooter, PageLoading } from '../../components/page';
import { useConnectNowUserQuery, useIsConnectNowServiceAvailableQuery } from '../../graphQL';
import { getRoute, useNavigate } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { ConnectNowPageWrapper } from './components/ConnectNowPageWrapper';
import { PreEncounterForm } from './forms/PreEncounterForm';
import { useRedirectFromOnDemand } from './hooks/useRedirectFromOnDemand';
import { isDuringHoliday } from './utils/availability';

export const ConnectNowPreEncounterPage = (): JSX.Element => {
  useTitle(['Before We Connect', 'ConnectNow']);

  useRedirectFromOnDemand();

  const navigate = useNavigate();

  const { loading: checkingAvailability } = useIsConnectNowServiceAvailableQuery({
    onCompleted(data) {
      const isDuringServiceHours = data.isConnectNowServiceAvailable ?? false;

      if (!isDuringServiceHours || isDuringHoliday()) {
        navigate(getRoute('connectNowHome', {}), { replace: true });
      }
    },
    onError() {
      navigate(getRoute('connectNowHome', {}), { replace: true });
    },
  });

  const { data: patientData, loading, error, refetch } = useConnectNowUserQuery();

  if (loading || checkingAvailability) {
    return <PageLoading pageName="ConnectNow pre encounter page" />;
  }

  if (error) {
    return <PageError showContact onRefreshPress={refetch} />;
  }

  const currentPhone = patientData?.currentHubUser.phone ?? '';

  return (
    <ConnectNowPageWrapper testID="connect-now-pre-encounter-page">
      <>
        <Layout.VStack space={7}>
          <Heading.h2>Before we connect, please answer a few short questions</Heading.h2>

          <PreEncounterForm currentPhone={currentPhone} />
        </Layout.VStack>

        <PageFooter />
      </>
    </ConnectNowPageWrapper>
  );
};
