import { JSX } from 'react';
import { Heading, Layout, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { getStylesheet } from '../../../styles';

export const GeolocationBlocked = (): JSX.Element => {
  return (
    <Layout.VStack space={8}>
      <Layout.VStack {...styles.textStack} space={4}>
        <IconAlertTriangle aria-hidden alignSelf="center" color="danger.600" size={7} />

        <Heading.h3 level={1}>Invalid Location</Heading.h3>

        <Text.para>The Mantra Care Hub is only available to students inside the U.S.</Text.para>

        <Text.para>Please contact your school’s counseling center for support.</Text.para>
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  textStack: {
    alignItems: 'stretch',
  },
});
