import { defaultTo } from 'lodash';
import { useEffect } from 'react';
import { PageError, PageLoading } from '../../components/page';
import { useModuleSimpleById } from '../../content/module/hooks/useModuleSimpleById';
import { SkillSlides } from '../../content/skill/components/SkillSlides';
import { SkillDocument } from '../../content/skill/SkillTypes';
import { useSkillContext } from '../../contexts';
import { useGetUpNextSkillQuery, useLoadUnfinishedSkillReflectionQuery } from '../../graphQL';
import { getRoute, RouteParams, useNavigate, useParams, useSearchParams } from '../../routes';
import { useTitle } from '../../utils/useTitle';

type SkillPageParams = RouteParams<'skill'>;

export const SkillPage = (): JSX.Element => {
  const navigate = useNavigate();

  const params = useParams<SkillPageParams>();
  const moduleId = defaultTo(params.moduleId, '');
  const skillId = defaultTo(params.skillId, '');

  const [searchParams] = useSearchParams();
  const isIntro = Boolean(searchParams.get('intro'));
  const isPathway = Boolean(searchParams.get('path'));

  const [foundModule, { loading: moduleLoading, error: moduleError, refetch: moduleRefetch }] =
    useModuleSimpleById({
      moduleId,
    });

  const {
    data: foundReflection,
    loading: reflectionLoading,
    error: reflectionError,
    refetch: reflectionRefetch,
  } = useLoadUnfinishedSkillReflectionQuery({
    variables: {
      skillId,
    },
  });

  const {
    data: foundUpNext,
    loading: upNextLoading,
    error: upNextError,
    refetch: upNextRefetch,
  } = useGetUpNextSkillQuery({
    variables: {
      skillId,
      moduleId,
      isPathway,
    },
  });

  const refetchAll = (): void => {
    moduleRefetch();
    void reflectionRefetch();
    void upNextRefetch();
  };

  const { skill, skillState, slideCount, slideNumber } = useSkillContext();

  useTitle([skill?.data.title ?? 'Skill', 'Self Care']);

  useEffect(() => {
    // If the slide set in the URL wasn't a valid number, then redirect again to the first slide.
    if (slideNumber !== Number(params.slide)) {
      navigate(
        getRoute('skill', {
          moduleId,
          skillId,
          slide: String(slideNumber),
        }),
      );
      return;
    }

    // If the slide set in the URL is larger than the slideCount, then redirect to the last slide.
    if (slideCount !== undefined && slideCount > 0 && slideNumber > 1 && slideNumber > slideCount) {
      navigate(
        getRoute('skill', {
          moduleId,
          skillId,
          slide: String(slideCount),
        }),
      );
    }
  }, [slideNumber, skill]);

  if (skillState.loading || moduleLoading || reflectionLoading || upNextLoading) {
    return <PageLoading pageName="skill" />;
  }
  if (skillState.error || moduleError || reflectionError || upNextError) {
    return <PageError goBackTo={getRoute('module', { moduleId })} onRefreshPress={refetchAll} />;
  }
  if (!skill || !foundModule || !foundUpNext) {
    return <PageError goBackTo={getRoute('module', { moduleId })} onRefreshPress={refetchAll} />;
  }

  const { moduleWillBeComplete, upNextSkill, upNextSkillIsComplete } = foundUpNext.getUpNextSkill;

  return (
    <SkillSlides
      isIntro={isIntro}
      isPathway={isPathway}
      module={foundModule}
      questions={foundReflection?.loadUnfinishedSkillReflection?.questions}
      moduleWillBeComplete={moduleWillBeComplete}
      upNextSkill={upNextSkill as SkillDocument | undefined}
      upNextSkillIsCompleted={upNextSkillIsComplete}
    />
  );
};
