import { Platform } from 'react-native';
import { config } from '../config';
import Sentry from '../utils/sentry';

if (config.sentry.dsn) {
  /**
   * For local dev debugging purposes, add:
   *   debug: true,
   */
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release: config.sentry.release,
    integrations: [Sentry.reactNativeTracingIntegration()],
  });

  Sentry.setTag('platform', Platform.OS);
}
