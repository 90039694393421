import React, { useState } from 'react';
import { PageError, PageLoading } from '../../components/page';
import { useSkillReflectionGetUserAnswersQuery } from '../../graphQL';
import { RouteParams, useParams } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { QuestionsList } from './QuestionsList';
import { ReflectionEdit } from './ReflectionEdit';

type ReflectionPageParams = RouteParams<'reflection'>;
type Question = { questionKey: string; question: string; answer: string };

export const ReflectionPage = (): JSX.Element => {
  const [questionToEdit, setQuestionToEdit] = useState<Question | undefined>();
  const { reflectionId } = useParams<ReflectionPageParams>();

  const showEdit = questionToEdit !== undefined;

  const {
    data: reflectionData,
    loading,
    error,
    refetch,
  } = useSkillReflectionGetUserAnswersQuery({
    variables: {
      reflectionId: Number(reflectionId),
    },
  });

  const skillTitle = reflectionData?.skillReflectionGetUserAnswers?.title ?? 'Skill';
  useTitle([showEdit ? 'Edit Reflection' : skillTitle, 'Reflections']);

  if (reflectionId === undefined || error !== undefined) {
    return <PageError onRefreshPress={refetch} />;
  }
  if (loading) {
    return <PageLoading pageName="reflection" />;
  }

  const answersData = reflectionData?.skillReflectionGetUserAnswers ?? undefined;
  if (answersData === undefined) {
    return <PageError onRefreshPress={refetch} />;
  }

  const onEditPress = (question: Question) => (): void => {
    setQuestionToEdit(question);
  };

  const onEditClose = (wasUpdated: boolean): void => {
    if (wasUpdated) {
      void refetch({
        reflectionId: Number(reflectionId),
      });
    }

    setQuestionToEdit(undefined);
  };

  if (showEdit) {
    return <ReflectionEdit {...questionToEdit} reflectionId={reflectionId} onClose={onEditClose} />;
  }

  return <QuestionsList {...answersData} reflectionId={reflectionId} onEditPress={onEditPress} />;
};
