import { IBoxProps } from 'native-base';
import { ReactNode, useEffect } from 'react';
import { getStylesheet } from '../../styles';
import { validateTitle } from '../../utils/useTitle';
import { Layout } from '../core';
import { APP_PAGE_MAX_WIDTHS } from '../layout/app/appSizes';

type PageContentProps = IBoxProps & {
  children: ReactNode;
  pageSize?: keyof typeof APP_PAGE_MAX_WIDTHS;
  testID: string;
};

export const PageContent = ({
  children,
  paddingBottom = 24,
  pageSize = 'largest',
  ...contentStyles
}: PageContentProps): JSX.Element => {
  useEffect(() => {
    // Validate that this page had a page title set using useTitle().
    validateTitle(contentStyles.testID);
  }, []);

  const maxWidthPx = APP_PAGE_MAX_WIDTHS[pageSize];

  return (
    <Layout.View
      {...styles.content}
      maxWidth={`${maxWidthPx}px`}
      paddingBottom={paddingBottom}
      {...contentStyles}
    >
      {children}
    </Layout.View>
  );
};

const styles = getStylesheet({
  content: {
    alignSelf: 'center',
    width: '100%',
  },
});
