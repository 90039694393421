import { CircleIcon, IFlexProps } from 'native-base';
import { JSX, ReactElement, ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { Layout, Text } from './index';

type ListProps = IFlexProps & {
  children: ReactNode;
};

export const List = ({ children, ...props }: ListProps): JSX.Element => {
  return (
    <Layout.Flex {...styles.list} role="list" {...props}>
      {children}
    </Layout.Flex>
  );
};

type TextListItemProps = Layout.IHStackProps & {
  children: ReactNode;
  icon?: ReactElement;
};

export const Item = ({ children, icon, ...hStackProps }: TextListItemProps): JSX.Element => {
  return (
    <Layout.HStack {...hStackProps} role="listitem">
      {icon ?? <CircleIcon aria-hidden size={2} marginTop="6px" />}

      <Text.para marginLeft={3} flex={1}>
        {children}
      </Text.para>
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  list: {
    gap: 2,
  },
});
