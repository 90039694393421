import React, { useEffect } from 'react';
import { PageError, PageLoading } from '../../components/page';
import { useSkillContext } from '../../contexts';
import { getRoute, RouteParams, useNavigate, useParams } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { SelfCareQuizSlides } from './SelfCareQuizSlides';

type SelfCareQuizParams = RouteParams<'selfCareQuiz'>;

export const SelfCareQuizPage = (): JSX.Element => {
  useTitle(['Find your Pathway', 'Self Care']);

  const navigate = useNavigate();
  const params = useParams<SelfCareQuizParams>();

  const { skill, skillState, slideCount, slideNumber } = useSkillContext();

  useEffect(() => {
    // If the slide set in the URL wasn't a valid number, then redirect again to the first slide.
    if (slideNumber !== Number(params.slide)) {
      navigate(
        getRoute('selfCareQuiz', {
          slide: String(slideNumber),
        }),
      );
      return;
    }

    // If the slide set in the URL is larger than the slideCount, then redirect to the last slide.
    if (slideCount !== undefined && slideNumber > 1 && slideNumber > slideCount) {
      navigate(
        getRoute('selfCareQuiz', {
          slide: String(slideCount),
        }),
      );
    }
  }, [slideNumber, skill]);

  if (!skillState.loaded) {
    return <PageLoading pageName="self care quiz" />;
  }
  if (skillState.error) {
    return <PageError goBackTo={getRoute('home', {})} />;
  }
  if (!skill) {
    return <PageError goBackTo={getRoute('home', {})} />;
  }

  return <SelfCareQuizSlides />;
};
