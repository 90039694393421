import { Component, useCallback, useMemo, useState } from 'react';
import { AccessibilityInfo, findNodeHandle, Platform } from 'react-native';
import { useAccessibilityContext } from '../../contexts/accessibilityContext';
import { visuallyHiddenStyle } from '../../utils/accessibility';
import Sentry from '../../utils/sentry';
import { Button, Layout } from '../core';

export const SkipToMain = (): JSX.Element => {
  const { mainRef } = useAccessibilityContext();

  const [hasFocus, setHasFocus] = useState(false);

  const onBlur = (): void => {
    setHasFocus(false);
  };
  const onFocus = (): void => {
    setHasFocus(true);
  };

  const onSkipToMainPress = useCallback((): void => {
    if (Platform.OS === 'web') {
      const mainRefEl = mainRef.current as unknown as HTMLElement | undefined;
      const mainIdEl = document.getElementById('main');
      const mainTagEls = document.getElementsByTagName('main');

      const mainEl: HTMLElement | undefined = mainRefEl ?? mainIdEl ?? mainTagEls[0];
      if (!mainEl) {
        // Could not find mainEl on this page, this needs to be fixed.
        // eslint-disable-next-line no-console
        console.error('No main element found for skip-to-main link.');
        Sentry.captureException(
          new Error(
            `No main element found for skip-to-main link on page '${
              document.title ?? '(no page title found)'
            }'.`,
          ),
        );
      }

      const topHeadings = (mainEl ?? document).getElementsByTagName('h1');
      if (topHeadings[0]) {
        // Prefer to focus on the h1 heading if we can find it.
        topHeadings[0].setAttribute('tabIndex', '-1');
        topHeadings[0].focus();
        return;
      }

      if (mainEl) {
        // Fallback to focusing on the main element.
        mainEl.setAttribute('tabIndex', '-1');
        mainEl.focus();
      }

      return;
    }

    if (!mainRef.current) {
      return;
    }

    const reactTag = findNodeHandle(mainRef.current as unknown as Component) ?? undefined;
    if (reactTag === undefined) {
      return;
    }

    // iOS (maybe android?)
    AccessibilityInfo.setAccessibilityFocus(reactTag);
  }, [mainRef.current]);

  const styles = useMemo(() => {
    return hasFocus
      ? {
          alignItems: 'flex-start',
          padding: 1,
          width: '100%',
        }
      : {
          ...visuallyHiddenStyle,
        };
  }, [hasFocus]);

  return (
    <Layout.View {...styles}>
      <Button.tertiarySmall
        isLink
        onBlur={onBlur}
        onFocus={onFocus}
        onPress={onSkipToMainPress}
        testID="skip-to-main"
      >
        Skip to main content
      </Button.tertiarySmall>
    </Layout.View>
  );
};
