import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import { JSX } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Platform, StyleSheet } from 'react-native';
import MaskInput, { Mask, MaskInputProps } from 'react-native-mask-input';
import { error as errorColors, secondary } from '../../styles/colors';
import { hexToRgba } from '../../utils/color';
import { FormErrorInput } from './FormErrorInput';
import { FormHelperText } from './FormHelperText';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

type FormInputMaskedProps<TFieldValues extends FieldValues> = MaskInputProps &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  Omit<FormLabelProps, 'inputId'> & {
    error?: FieldErrors<TFieldValues>[string];
    helperText?: string;
    hideLabel?: boolean;
    isDisabled?: boolean;
    mask: Mask;
  };

export function FormInputMasked<TFieldValues extends FieldValues>({
  control,
  error,
  mask,
  name,
  onLayout,
  placeholder,
  inputMode,
  helperText = '',
  hideLabel = false,
  isDisabled = false,
  isRequired = false,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...inputProps
}: FormInputMaskedProps<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(isRequired),
      message: 'This field is required.',
    }),
  };

  const finalStyles = [
    maskInputStyle.input,
    isDisabled ? maskInputStyle.disabled : {},
    error ? maskInputStyle.invalid : {},
  ];

  const nativeId = name;
  const inputId = `${nativeId}-input`;

  const ariaLabel = label ? { accessibilityLabel: label } : {};
  const ariaDescribedBy =
    [helperText ? `${nativeId}-helptext` : undefined, error ? `${nativeId}-feedback` : undefined]
      .filter(Boolean)
      .join(' ') || undefined;

  return (
    <FormControl isInvalid={Boolean(error?.type)} nativeID={nativeId} onLayout={onLayout}>
      {!hideLabel && (
        <FormLabel
          inputId={inputId}
          label={label}
          necessityIndicator={necessityIndicator}
          isRequired={isRequired}
        />
      )}

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <MaskInput
            {...inputProps}
            {...ariaLabel}
            aria-describedby={ariaDescribedBy}
            id={inputId}
            value={value}
            onChangeText={onChange}
            onBlur={onBlur}
            mask={mask}
            placeholder={placeholder}
            placeholderTextColor={secondary[400]}
            style={finalStyles}
            editable={!isDisabled}
            ref={ref}
            inputMode={inputMode}
            inputAccessoryViewID="doneAccessoryID"
          />
        )}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {error && <FormErrorInput nativeID={nativeId}>{error.message}</FormErrorInput>}
    </FormControl>
  );
}

const maskInputStyle = StyleSheet.create({
  input: {
    height: 40,
    width: '100%',
    borderColor: secondary[300],
    backgroundColor: 'white',
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
  },

  disabled: {
    color: hexToRgba(secondary.alpha[60], 0.6),
    borderColor: secondary[300],
    backgroundColor: secondary[200],
    ...(Platform.OS === 'web' ? { cursor: 'not-allowed' } : {}),
  },

  invalid: {
    borderColor: errorColors[600],
    borderWidth: 2,
  },
});
