import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatWithMask } from 'react-native-mask-input';
import { Button, Layout, StatusFeedback } from '../../../components/core';
import { FormInputMasked } from '../../../components/form';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { UpdateHubUserErrorCode, useUpdateHubUser2Mutation } from '../../../graphQL';
import { getStylesheet } from '../../../styles';
import { PHONE_MASK } from '../../../utils/phone';

type PhoneNumberFormProps = {
  onContinue: () => void;
};

export const PhoneNumberForm = ({ onContinue }: PhoneNumberFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      phoneNumber: '',
      submit: undefined,
    },
  });

  const [setUserPhone, { loading }] = useUpdateHubUser2Mutation({
    onCompleted: async ({ updateHubUser }) => {
      const responseType = updateHubUser?.__typename;
      if (responseType === 'UpdateHubUserSuccess') {
        onContinue();
        return;
      }

      if (responseType === 'UpdateHubUserError') {
        if (updateHubUser.errorCode === UpdateHubUserErrorCode.DuplicatePhone) {
          formContext.setError('phoneNumber', {
            type: UpdateHubUserErrorCode.DuplicatePhone,
            message: 'This phone number is already in use. Please enter a new number',
          });
          return;
        }

        if (updateHubUser.errorCode === UpdateHubUserErrorCode.InvalidPhone) {
          formContext.setError('phoneNumber', {
            type: UpdateHubUserErrorCode.InvalidPhone,
            message: 'Please enter a valid US phone number.',
          });
          return;
        }
      }

      formContext.setError('submit', {
        type: 'error',
        message: 'An error occurred. Please try again.',
      });
    },
    onError: savePhoneError => {
      formContext.setError('submit', savePhoneError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const watchedPhone = watch('phoneNumber');

  const isSubmitDisabled = useMemo((): boolean => {
    return watchedPhone.length < 12;
  }, [watchedPhone]);

  const submitForm = (): void => {
    clearErrors();

    void handleSubmit(async ({ phoneNumber }): Promise<void> => {
      const unmaskedPhoneNumber = formatWithMask({
        text: phoneNumber,
        mask: PHONE_MASK,
      }).unmasked;

      await setUserPhone({
        variables: {
          updates: {
            phone: unmaskedPhoneNumber,
          },
        },
      });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={8}>
        <Layout.VStack space={2}>
          <FormInputMasked
            label="Enter your cell number"
            name="phoneNumber"
            mask={PHONE_MASK}
            placeholder="e.g. 555-555-5555"
            helperText="By signing up for SMS updates, you agree to receive text messages from Mantra Health.
            Standard text and data rates apply. You can opt out at any time."
            autoComplete="tel"
            inputMode="tel"
            keyboardType="phone-pad"
            control={control}
            error={errors.phoneNumber}
            rules={{ minLength: 12 }}
            blurOnSubmit={false}
            returnKeyType="next"
            onSubmitEditing={submitForm}
          />

          {errors.submit && (
            <StatusFeedback.danger testID="phone-number-input-warning" Icon={IconAlertTriangle}>
              {errors.submit.message}
            </StatusFeedback.danger>
          )}
        </Layout.VStack>

        <Layout.VStack {...styles.buttons} space={2}>
          <Button.primaryLarge
            testID="button-onboarding-phone-submit"
            onPress={submitForm}
            isDisabled={isSubmitDisabled || loading}
          >
            Confirm
          </Button.primaryLarge>

          <Button.tertiaryLarge
            disabled={loading}
            testID="button-onboarding-phone-skip"
            onPress={onContinue}
          >
            Skip for Now
          </Button.tertiaryLarge>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};

const styles = getStylesheet({
  buttons: {
    marginX: 16,
  },
});
