import { getStylesheet } from '../../styles';
import { Heading, Layout, Link, Text } from '../core';
import { HeadingLevel, incrementHeadingLevel } from '../core/Heading';

export const PageCrisis = ({ headingLevel = 2 }: { headingLevel?: HeadingLevel }): JSX.Element => {
  const headingId = 'page-crisis-heading';

  return (
    <Layout.Box {...styles.warningBox}>
      <Layout.VStack space={4}>
        <Heading.h5 level={headingLevel} id={headingId}>
          If you are experiencing a crisis:
        </Heading.h5>

        <Layout.Flex {...styles.list} aria-labelledby={headingId} role="list">
          <Layout.Box role="listitem">
            <Heading.h6 level={incrementHeadingLevel(headingLevel)}>Emergency: Dial 988</Heading.h6>
          </Layout.Box>

          <Layout.Box role="listitem">
            <Heading.h6 level={incrementHeadingLevel(headingLevel)}>
              National Suicide Prevention Hotline
            </Heading.h6>

            <Text.paraSmall>1 (800) 273-8255</Text.paraSmall>

            <Link.caption to="https://suicidepreventionlifeline.org/chat/">
              Suicide Prevention Lifeline Web Chat
            </Link.caption>
          </Layout.Box>

          <Layout.Box role="listitem">
            <Heading.h6 level={incrementHeadingLevel(headingLevel)}>Crisis Text Line</Heading.h6>

            <Text.paraSmall>Text HOME to 741-741</Text.paraSmall>
          </Layout.Box>

          <Layout.Box role="listitem">
            <Heading.h6 level={incrementHeadingLevel(headingLevel)}>
              National Hopeline Network
            </Heading.h6>

            <Text.paraSmall>1 (800) 784-2433</Text.paraSmall>
          </Layout.Box>
        </Layout.Flex>
      </Layout.VStack>
    </Layout.Box>
  );
};

const styles = getStylesheet({
  warningBox: {
    padding: 4,
    backgroundColor: 'warning.alpha.20:alpha.20',
    borderColor: 'warning.300',
    borderRadius: 8,
    borderWidth: 1,
  },

  list: {
    gap: 4,
  },
});
