import { differenceInWeeks, format } from 'date-fns';
import { defaultTo } from 'lodash';
import { Avatar } from 'native-base';
import React, { JSX, useMemo } from 'react';
import { Badge, Button, Heading, Layout, Pressable, Text } from '../../../components/core';
import { IconAlertCircleFilled } from '../../../components/icons/IconAlertCircle';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import { IconMessageSquare } from '../../../components/icons/IconMessageSquare';
import { IconUser } from '../../../components/icons/IconUser';
import {
  CoachingStatus,
  useCoachOverviewQuery,
  useGetSkillAssignmentCountsForUserQuery,
} from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { CoachingProvider, useCoachingContext } from '../../coaching/context/coachingContext';

type CoachingActiveCardProps = {
  isCoachingActive?: boolean;
};

export const CoachingDashboardCard = (): JSX.Element => {
  return (
    <CoachingProvider>
      <CoachingCardStatusSwitch />
    </CoachingProvider>
  );
};

const CoachingCardStatusSwitch = (): JSX.Element => {
  const { coachingStatus } = useCoachingContext();

  if (coachingStatus === CoachingStatus.Active) {
    return <CoachingActiveCard />;
  }

  if (coachingStatus === CoachingStatus.Onboarding) {
    return <CoachingOnboardingCard />;
  }

  if (coachingStatus === CoachingStatus.Inactive) {
    return <CoachingActiveCard isCoachingActive={false} />;
  }

  return <></>;
};

const CoachingActiveCard = ({ isCoachingActive = true }: CoachingActiveCardProps): JSX.Element => {
  const { data: assignedSkillCountData } = useGetSkillAssignmentCountsForUserQuery();
  const { data: coachOverviewData } = useCoachOverviewQuery();
  const coachName = coachOverviewData?.coachOverview.coachName ?? '';
  const coachAvatarUrl = coachOverviewData?.coachOverview.coachAvatarUrl ?? '';
  const hasUnreadMessages = coachOverviewData?.coachOverview.hasUnreadMessages ?? false;
  const coachingStartDate = coachOverviewData?.coachOverview.coachingStartDate ?? null;
  const coachingEndDate = coachOverviewData?.coachOverview.coachingEndDate ?? null;

  const weekCount = useMemo(() => {
    if (coachingStartDate != null) {
      return differenceInWeeks(new Date(), new Date(coachingStartDate));
    }
    return 0;
  }, [coachingStartDate]);

  const navigate = useNavigate();

  const unseenAssignedSkills = defaultTo(
    assignedSkillCountData?.getSkillAssignmentCountsForUser.notSeen,
    0,
  );
  const skillOrSkills = `skill${unseenAssignedSkills > 1 ? 's' : ''}`;

  const onViewPress = (): void => {
    navigate(getRoute('coaching', {}));
  };

  const onChatPress = (): void => {
    navigate(getRoute('coachingChat', {}));
  };

  const headerId = 'coaching-dashboard-card-header';

  return (
    <Layout.VStack {...style.coachingCard}>
      <Layout.Flex {...style.coachingCardTop}>
        <Layout.HStack space={2} alignItems="center">
          {coachAvatarUrl ? (
            <Avatar _image={{ alt: 'Coach Avatar' }} aria-hidden source={{ uri: coachAvatarUrl }} />
          ) : (
            <Layout.View {...style.iconUserWrapper} aria-hidden>
              <IconUser {...style.iconUser} size={10} />
            </Layout.View>
          )}

          <Layout.VStack id={headerId} role="heading" aria-level={2} space={1}>
            <Heading.h3>Coaching</Heading.h3>

            <Text.para>{`with ${coachName}`}</Text.para>
          </Layout.VStack>
        </Layout.HStack>

        <Layout.HStack space={2}>
          {isCoachingActive && (
            <Layout.HStack>
              <Button.secondaryMedium
                isLink
                testID="button-navigate-to-coach-chat"
                leftIcon={<IconMessageSquare aria-label="Text chat with your coach" />}
                onPress={onChatPress}
              />
              {hasUnreadMessages && <Layout.Center {...style.notification} />}
            </Layout.HStack>
          )}

          <Button.primaryMedium
            aria-label="Coaching"
            isLink
            testID="button-navigate-to-coaching"
            rightIcon={<IconChevronRight size={5} />}
            onPress={onViewPress}
          >
            View
          </Button.primaryMedium>
        </Layout.HStack>
      </Layout.Flex>

      <Layout.HStack {...style.coachingCardBottom}>
        {isCoachingActive && (
          <Pressable
            aria-label={
              unseenAssignedSkills > 0
                ? `${unseenAssignedSkills} coaching ${skillOrSkills} to complete`
                : `Coaching Week ${weekCount + 1}`
            }
            isLink
            padding={4}
            onPress={onViewPress}
            testID="pressable-to-coaching-dashboard"
          >
            {unseenAssignedSkills > 0 && (
              <Layout.HStack space={2} alignItems="center">
                <IconAlertCircleFilled size={5} color="primary.600" aria-label="Alert" />

                <Heading.h6 bold>
                  {`${unseenAssignedSkills} ${skillOrSkills} to complete`}
                </Heading.h6>
              </Layout.HStack>
            )}

            {unseenAssignedSkills <= 0 && <Heading.h6 bold>Week {weekCount + 1}</Heading.h6>}
          </Pressable>
        )}

        {!isCoachingActive && coachingEndDate !== null && (
          <Text.para padding={4}>Ended {format(new Date(coachingEndDate), 'M/d/yy')}</Text.para>
        )}
      </Layout.HStack>
    </Layout.VStack>
  );
};

const CoachingOnboardingCard = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Layout.Shift {...style.dashboardBox}>
      <Layout.VStack {...style.dashboardStack} space={3}>
        <Badge.warningSubtle {...style.recommendedBadge}>Pending</Badge.warningSubtle>

        <Heading.h3 level={2}>Complete your coaching onboarding</Heading.h3>

        <Layout.VStack space={7}>
          <Text.para>
            You're just a few steps away from connecting with your coach. Complete your onboarding
            to get started.
          </Text.para>

          <Button.primaryLarge
            {...style.dashboardButton}
            aria-label="Complete coaching onboarding tasks"
            isLink
            testID="button-dashboard-navigate-to-coaching-onboarding"
            onPress={() => navigate(getRoute('coachingOnboarding', {}))}
          >
            Complete onboarding tasks
          </Button.primaryLarge>
        </Layout.VStack>
      </Layout.VStack>
    </Layout.Shift>
  );
};

const style = getStylesheet({
  coachingCard: {
    backgroundColor: 'white',
    borderRadius: 8,
    justifyContent: 'space-between',
    shadow: 'large',
    width: '100%',
  },

  coachingCardTop: {
    borderBottomColor: 'secondary.200',
    borderBottomWidth: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
    justifyContent: 'space-between',
    padding: 4,
  },

  coachingCardBottom: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  dashboardBox: {
    backgroundColor: 'white',
    borderColor: 'secondary.200',
    borderRadius: 8,
    borderWidth: 1,
    gap: 6,
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: 6,
  },

  dashboardButton: {
    alignSelf: 'flex-start',
  },

  dashboardStack: {
    flex: 1,
  },

  iconUser: {
    color: 'secondary.300',
  },

  iconUserWrapper: {
    backgroundColor: 'secondary.100',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'secondary.300',
    overflow: 'hidden',
  },

  notification: {
    backgroundColor: 'danger.600',
    borderRadius: 10,
    height: 5,
    position: 'absolute',
    right: -5,
    top: -5,
    width: 5,
  },

  recommendedBadge: {
    alignSelf: 'flex-start',
  },
});
