import * as Sentry from '@sentry/react-native';
import { Platform } from 'react-native';

export default Sentry;

export const logStartup = (step: string, details: Record<string, string | boolean> = {}): void => {
  // Only log for Android right now
  if (Platform.OS === 'android') {
    const message = `APP_INIT: ${step}`;
    Sentry.addBreadcrumb({
      category: 'app.startup',
      message,
      level: 'info',
      data: details,
    });
  }
};
