import { JSX } from 'react';
import { PageError, PageLoading } from '../../components/page';
import { useTitle } from '../../utils/useTitle';
import { CoachingDashboard } from './components/CoachingDashboard';
import { useCoachingContext } from './context/coachingContext';
import { useCoachingRedirectToOnboarding } from './hooks/useCoachingRedirectToOnboarding';

export const CoachingHomePage = (): JSX.Element => {
  useTitle('Coaching');

  const { coachingStatus, coachingStatusState } = useCoachingContext();

  // Redirects the user to the coaching onboarding page if they are not already in coaching.
  useCoachingRedirectToOnboarding();

  if (coachingStatusState.loading || coachingStatus === undefined) {
    return <PageLoading pageName="coaching" />;
  }

  if (coachingStatusState.error !== undefined) {
    return <PageError onRefreshPress={coachingStatusState.refetch} />;
  }

  return <CoachingDashboard coachingStatus={coachingStatus} />;
};
