import { FormControl, IFormControlProps } from 'native-base';

type FormErrorInputProps = IFormControlProps & {
  nativeID: string; // This must match the native ID given to FormControl.
};

export function FormErrorInput({ children, nativeID, ...props }: FormErrorInputProps): JSX.Element {
  // This is to fix an error/typo in native base where it used the helptext id instead of the feedback id.
  const feedbackId = `${nativeID}-feedback`;

  return (
    <FormControl.ErrorMessage {...props} nativeID={feedbackId}>
      Error: {children}
    </FormControl.ErrorMessage>
  );
}
