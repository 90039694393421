import { JSX } from 'react';
import { Heading, Layout, Text } from '../../components/core';
import { PageContent, PageError, PageFooter, PageHeader, PageLoading } from '../../components/page';
import { Portals } from '../../content/portal/portals';
import { usePortalContext } from '../../contexts/portalContext';
import { RecommendationOptions } from '../../graphQL';
import { getStylesheet } from '../../styles';
import { useTitle } from '../../utils/useTitle';
import {
  AssessmentVisitCTACard,
  CustomClinicalRecCTACard,
  EmotionalWellnessCoachingCTACard,
  ExecutiveFunctionCoachingCTACard,
  SelfCareCTACard,
  TherapyCTACard,
} from './components/CareRecommendationCTACards';
import {
  CareRecommendationCrisisFeedback,
  CoachingSelfCareFeedback,
} from './components/CareRecommendationStatusFeedbacks';
import {
  ConnectNowCTACard,
  SelfCareSelfServiceCTACard,
  TogetherAllCTACard,
} from './components/SelfServiceOptions';
import { CareRecommendationOutputs, useCareRecommendation } from './hooks/useCareRecommendation';

const { AssessmentVisit, Coaching, CustomClinicalRec, Therapy } = RecommendationOptions;

export const RecommendationPage = (): JSX.Element => {
  useTitle('Your recommendation');

  const [careRecData, { loading, error }] = useCareRecommendation();

  if (loading) {
    return <PageLoading pageName="recommendation" />;
  }
  if (error) {
    return <PageError />;
  }

  const { careRecommendation } = careRecData;

  return (
    <PageContent testID="recommendation-page">
      <Layout.Box {...styles.topStack}>
        <PageHeader
          title="Your personalized care recommendation"
          undertext="Based on your answers, we have tailored care recommendations that best support your mental health care journey."
          testID="recommendation-header"
          paddingBottom={0}
        />

        {careRecommendation === AssessmentVisit && <AssessmentVisitCards />}
        {careRecommendation === Coaching && <CoachingCards />}
        {careRecommendation === CustomClinicalRec && <CustomClinicCards />}
        {careRecommendation === Therapy && (
          <TherapyCards isUserDeactivated={careRecData.isUserDeactivated ?? false} />
        )}

        <SelfServiceCards {...careRecData} careRecommendation={careRecommendation} />
      </Layout.Box>

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  topStack: {
    gap: 8,
  },
});

const AssessmentVisitCards = (): JSX.Element => {
  return (
    <>
      <CareRecommendationCrisisFeedback key="assessment-visit-status-feedback" />
      <AssessmentVisitCTACard key="assessment-visit" />
    </>
  );
};

const CoachingCards = (): JSX.Element => {
  const { onlyIfPortalIsActive } = usePortalContext();
  const executiveFunctioningModuleActive = onlyIfPortalIsActive(
    Portals.ExecutiveFunctioning,
    true,
    false,
  );

  return (
    <>
      {executiveFunctioningModuleActive && <ExecutiveFunctionCoachingCTACard />}

      <EmotionalWellnessCoachingCTACard key="emotional-wellness-coaching" />

      <SelfCareCTACard key="self-care" />

      <CoachingSelfCareFeedback key="coaching-self-care-feedback" />
    </>
  );
};

const CustomClinicCards = (): JSX.Element => {
  return (
    <>
      <CareRecommendationCrisisFeedback key="custom-clinical-rec-status-feedback" />

      <CustomClinicalRecCTACard key="custom-clinical-rec" />
    </>
  );
};

const TherapyCards = ({ isUserDeactivated }: { isUserDeactivated: boolean }): JSX.Element => {
  return <TherapyCTACard key="therapy" isUserDeactivated={isUserDeactivated} />;
};

const SelfServiceCards = ({
  careRecommendation,
  connectNowEnabled,
  togetherAllEnabled,
}: CareRecommendationOutputs): JSX.Element => {
  return (
    <>
      <Layout.VStack space={2}>
        <Heading.h3>Additional self-serve tools</Heading.h3>

        <Text.paraSmall>
          Access the following tools at any time from within the Mantra Care Hub.
        </Text.paraSmall>
      </Layout.VStack>

      <Layout.VStack space={7}>
        {togetherAllEnabled && careRecommendation !== AssessmentVisit && (
          <TogetherAllCTACard key="togetherall-self-service-cta-card" />
        )}

        {connectNowEnabled && <ConnectNowCTACard key="connect-now-self-service-cta-card" />}

        {careRecommendation !== Coaching && (
          <SelfCareSelfServiceCTACard key="self-care-self-service-cta-card" />
        )}
      </Layout.VStack>
    </>
  );
};
