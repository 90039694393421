import { JSX } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, DividerSoft, Layout } from '../../../components/core';
import { FormErrorSubmit } from '../../../components/form';
import { usePortalContext } from '../../../contexts/portalContext';
import { useHubUserAcceptTermsMutation } from '../../../graphQL';
import { FormTerms } from '../components/FormTerms';

type OfAgeFormProps = {
  onSaved: () => void;
};

export const OfAgeForm = ({ onSaved }: OfAgeFormProps): JSX.Element => {
  const { removeBlock } = usePortalContext();
  const formContext = useForm({
    defaultValues: {
      terms: false,
      submit: undefined,
    },
  });

  const [signTerms, { loading }] = useHubUserAcceptTermsMutation({
    onCompleted: async (): Promise<void> => {
      // Make sure to remove blocks and refresh the portals to clear out previous portal access.
      await removeBlock({ isLogout: false });

      onSaved();
    },
    onError: saveNameError => {
      formContext.setError('submit', saveNameError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();

    void handleSubmit(async (): Promise<void> => {
      await signTerms();
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={8} width="100%">
        <Layout.VStack space={3} width="100%">
          <DividerSoft />

          <FormTerms name="terms" control={control} errors={errors} />

          <DividerSoft />
        </Layout.VStack>

        <Layout.VStack space={4}>
          {errors.submit && <FormErrorSubmit>{errors.submit.message}</FormErrorSubmit>}

          <Button.primaryMedium
            isLink
            isLoading={loading}
            testID="button-of-age-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
