import { Platform } from 'react-native';
import {
  PreLoginOrPasswordLoginMutation,
  usePreLoginOrPasswordLoginMutation,
} from '../../../graphQL';
import { isRateLimited } from '../../../utils/graphql';
import { useAppType } from './useAppType';
import { useMobilePostLogin } from './useMobilePostLogin';

export enum ErrorTypesLoginUser {
  Deactivated = 'deactivated',
  Failed = 'failed',
  RateLimit = 'rate-limit',
}

type ResponseArgs = Exclude<
  PreLoginOrPasswordLoginMutation['preLoginOrPasswordLogin'],
  { __typename: 'PreLoginOrPasswordLoginError' }
>;

type LoginError = {
  type: ErrorTypesLoginUser;
  message: string;
};

type LoginUserProps = {
  email: string;
  password?: string;
  mrn?: string;
};

type UseLoginUserProps = {
  onCompleted: (args: ResponseArgs) => void;
  onError: (error: LoginError) => void;
};
type UseLoginUserReturn = [(loginData: LoginUserProps) => Promise<void>, { loading: boolean }];

export const useLoginUser = ({ onCompleted, onError }: UseLoginUserProps): UseLoginUserReturn => {
  const appType = useAppType();

  const mobilePostLogin = useMobilePostLogin();

  const [hubLogin, { loading }] = usePreLoginOrPasswordLoginMutation({
    onCompleted: async ({ preLoginOrPasswordLogin }) => {
      const responseType = preLoginOrPasswordLogin.__typename;
      if (responseType === 'PreLoginOrPasswordLoginError') {
        if (preLoginOrPasswordLogin.errorCode === 'PasswordRequired') {
          onError({
            type: ErrorTypesLoginUser.Failed,
            message: preLoginOrPasswordLogin.message,
          });
        } else if (preLoginOrPasswordLogin.errorCode === 'Deactivated') {
          onError({
            type: ErrorTypesLoginUser.Deactivated,
            message: 'Your account has been disabled due to cancellation or discharge.',
          });
        } else {
          onError({
            type: ErrorTypesLoginUser.Failed,
            message: 'Invalid Credentials. Please check your email and password and try again.',
          });
        }
        return;
      }

      if (
        responseType === 'PreLoginOrPasswordLoginSuccess' &&
        Platform.OS !== 'web' &&
        preLoginOrPasswordLogin.jwt != null
      ) {
        await mobilePostLogin(
          preLoginOrPasswordLogin.jwt,
          preLoginOrPasswordLogin.hasHubOnboarded ?? false,
        );
      }

      onCompleted(preLoginOrPasswordLogin);
    },
    onError: authError => {
      if (isRateLimited(authError)) {
        onError({
          type: ErrorTypesLoginUser.RateLimit,
          message:
            'Your account has been locked due to too many unsuccessful login attempts. Please wait 15 minutes or reset your password to log in.',
        });
        return;
      }

      onError({
        type: ErrorTypesLoginUser.Failed,
        message: authError.message,
      });
    },
  });

  const loginUser = async ({ email, password, mrn }: LoginUserProps): Promise<void> => {
    await hubLogin({ variables: { app: appType, email, password, mrn } });
  };

  return [loginUser, { loading }];
};
