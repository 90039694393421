import { Link, StatusFeedback } from '../../../components/core';
import { IconAlertCircle } from '../../../components/icons/IconAlertCircle';
import { IconHelpCircle } from '../../../components/icons/IconHelpCircle';
import type { JSX } from 'react';

export const PatientPortalReauthInfo = (): JSX.Element => {
  return (
    <StatusFeedback.info Icon={IconHelpCircle} testID="patient-portal-reauth-info">
      Please re-authenticate to continue to the Mantra Patient Portal.
    </StatusFeedback.info>
  );
};

export const PatientPortalInvalidInfo = (): JSX.Element => {
  return (
    <StatusFeedback.warning Icon={IconAlertCircle} testID="patient-portal-invalid-info">
      Error: Could not log you into the Patient Portal. Please try logging in again or{' '}
      <Link.paraSmall bold to="mailto:hi@mantrahealth.com">
        reach out to us at hi@mantrahealth.com
      </Link.paraSmall>
      .
    </StatusFeedback.warning>
  );
};
