import { useToast } from 'native-base';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormInput } from '../../../components/form';
import { IconSend } from '../../../components/icons/IconSend';
import { useSendCoachChatMessageMutation } from '../../../graphQL';
import { usePrevious } from '../../../utils/usePrevious';

type MessageCoachFormProps = {
  onWriteMessage?: () => void;
};

export const MessageCoachForm = ({ onWriteMessage }: MessageCoachFormProps): JSX.Element => {
  const toast = useToast();
  const [sendCoachChatMessage] = useSendCoachChatMessageMutation({
    onCompleted: data => {
      if (data.sendCoachChatMessage.id === null) {
        toast.show({
          description: 'Message could not be sent',
          duration: 5000,
        });
      } else {
        formContext.setValue('content', '');
        toast.show({
          description: 'Message sent',
          duration: 3000,
        });
      }
    },
  });

  const formContext = useForm({
    defaultValues: {
      content: '',
    },
  });

  const currentContent = formContext.watch('content');
  const previousContent = usePrevious(currentContent) ?? '';

  // when the user writes a message, scroll to the bottom
  useEffect(() => {
    if (previousContent.length === 0 && currentContent.length > 0) {
      onWriteMessage?.();
    }
  }, [currentContent]);

  const { clearErrors, control, handleSubmit } = formContext;

  const submitForm = (): void => {
    clearErrors();

    void handleSubmit(async ({ content }): Promise<void> => {
      await sendCoachChatMessage({
        variables: {
          content,
        },
      });
    })();
  };

  return (
    <Layout.HStack space={2}>
      <Layout.View flex={1}>
        <FormProvider {...formContext}>
          <FormInput
            borderRadius={4}
            control={control}
            isRequired
            multiline
            numberOfLines={2}
            name="content"
            placeholder="Message coach"
            autoComplete="off"
          />
        </FormProvider>
      </Layout.View>

      <Button.primaryMedium
        height="100%"
        leftIcon={<IconSend accessibilityHidden />}
        onPress={submitForm}
        testID="button-sent-message-to-coach"
        accessibilityLabel="Send message to coach."
      />
    </Layout.HStack>
  );
};
