import { useEffect } from 'react';
import Sentry, { logStartup } from '../utils/sentry';
import { setupApollo, SetupApolloResponse } from './apolloSetup';
import { useSetupFonts } from './fonts';
import { heapInitialize } from './heap';
import { setupPolyfill } from './polyfillSetup';

// special case where we want Sentry to init when do imports, not when we call setupAppPlugins
import './sentry';

export { NativeBaseProvider } from './nativeBase';
export { PrismicProvider } from './prismic';

type PluginsResponse = SetupApolloResponse;

export function setupAppPlugins(): PluginsResponse {
  try {
    logStartup('Starting app plugins setup');

    logStartup('Initializing Heap');
    heapInitialize();
    logStartup('Heap initialized');

    logStartup('Setting up polyfills');
    setupPolyfill();
    logStartup('Polyfills setup complete');

    logStartup('Setting up Apollo');
    const apolloSetup = setupApollo();
    logStartup('Apollo setup complete');

    return apolloSetup;
  } catch (error) {
    logStartup('Error in setupAppPlugins', {
      error: error instanceof Error ? error.message : 'Unknown error',
    });
    Sentry.captureException(error);
    throw error;
  }
}

export function useSetup(): { isReady: boolean } {
  const { areFontsLoaded } = useSetupFonts();

  useEffect(() => {
    logStartup('Font loading status changed', { areFontsLoaded });
  }, [areFontsLoaded]);

  return { isReady: areFontsLoaded };
}
