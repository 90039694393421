import { defaultTo, kebabCase } from 'lodash';
import React from 'react';
import { Heading, Layout, Link, Text } from '../../../components/core';
import { HeadingLevel, incrementHeadingLevel } from '../../../components/core/Heading';
import { HubOrganizationResource } from '../../../graphQL';
import { getStylesheet } from '../../../styles';

type CampusResourceProps = {
  headingLevel: HeadingLevel;
  resource: HubOrganizationResource;
};

export const CampusResource = ({ headingLevel, resource }: CampusResourceProps): JSX.Element => {
  const name = resource.name;
  const description = defaultTo(resource.description, '');
  const contact = defaultTo(resource.contact, '');
  const email = defaultTo(resource.email, '');
  const hours = defaultTo(resource.hours, '');
  const location = defaultTo(resource.location, '');
  const phone = defaultTo(resource.phone, '');
  const website = defaultTo(resource.website, '');

  const detailsLevel = incrementHeadingLevel(headingLevel);

  const headingId = `resource-${kebabCase(name).toLowerCase()}-heading`;

  return (
    <Layout.VStack role="listitem">
      <Heading.h4 {...style.fieldName} level={headingLevel} id={headingId}>
        {name}
      </Heading.h4>

      {description && <Text.para>{description}</Text.para>}

      <Layout.Flex aria-labelledby={headingId} role="list">
        {contact && (
          <Layout.VStack space={2} role="listitem">
            <Heading.h6 {...style.fieldName} level={detailsLevel}>
              Contact
            </Heading.h6>
            <Text.para>{contact}</Text.para>
          </Layout.VStack>
        )}

        {email && (
          <Layout.VStack space={2} role="listitem">
            <Heading.h6 {...style.fieldName} level={detailsLevel}>
              Email
            </Heading.h6>
            <Text.para>{email}</Text.para>
          </Layout.VStack>
        )}

        {hours && (
          <Layout.VStack space={2} role="listitem">
            <Heading.h6 {...style.fieldName} level={detailsLevel}>
              Hours
            </Heading.h6>
            <Text.para>{hours}</Text.para>
          </Layout.VStack>
        )}

        {location && (
          <Layout.VStack space={2} role="listitem">
            <Heading.h6 {...style.fieldName} level={detailsLevel}>
              Location
            </Heading.h6>
            <Text.para>{location}</Text.para>
          </Layout.VStack>
        )}

        {phone && (
          <Layout.VStack space={2} role="listitem">
            <Heading.h6 {...style.fieldName} level={detailsLevel}>
              Phone
            </Heading.h6>
            <Text.para>{phone}</Text.para>
          </Layout.VStack>
        )}

        {website && (
          <Layout.VStack space={2} role="listitem">
            <Heading.h6 {...style.fieldName} level={detailsLevel}>
              Website
            </Heading.h6>
            <Link.paraSmall to={enforceHttps(website)}>{name} Website</Link.paraSmall>
          </Layout.VStack>
        )}
      </Layout.Flex>
    </Layout.VStack>
  );
};

const style = getStylesheet({
  fieldName: {
    paddingTop: 4,
  },
});

const enforceHttps = (website: string): string => {
  if (!website.match(/^https?:\/\//)) {
    return `https://${website}`;
  }
  return website;
};
