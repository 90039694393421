import { has } from 'lodash';
import { JSX } from 'react';
import { PageError, PageLoading } from '../../components/page';
import { Questionnaire, useFindCareStartQuery } from '../../graphQL';
import { RouteParams, useParams } from '../../routes';
import { useTitle } from '../../utils/useTitle';
import { FindCareAssessmentSlides } from './components/FindCareAssessmentSlides';
import { FindCarePageWrapper } from './components/FindCarePageWrapper';
import { FindCareSlideProvider } from './context/findCareSlideContext';

type AssessmentPageParams = RouteParams<'findCareAssessments'>;

const questionnaireKeyMapping = {
  'mental-health-history': 'mental-health-history',
  phq: 'phq8',
  gad: 'gad7',
} as const;

export type FindCareAssessmentKey = keyof typeof questionnaireKeyMapping;

export const FindCareAssessmentsPage = (): JSX.Element => {
  useTitle(['Assessment', 'Find Care']);

  const params = useParams<AssessmentPageParams>();
  const assessmentType = has(questionnaireKeyMapping, params.assessmentType ?? '')
    ? (params.assessmentType as FindCareAssessmentKey)
    : 'mental-health-history';

  const questionnaireKey = questionnaireKeyMapping[assessmentType];
  const { data: assessmentData, loading } = useFindCareStartQuery({
    variables: { questionnaireKey },
  });

  const questionnaire = assessmentData?.findCareStart?.questionnaire;
  const connectNowEnabled = assessmentData?.findCareStart?.connectNowEnabled ?? false;

  if (loading) {
    return <PageLoading pageName="find care assessment" />;
  }

  if (assessmentType === undefined || questionnaire === undefined) {
    return <PageError />;
  }

  return (
    <FindCareSlideProvider>
      <FindCarePageWrapper testID="find-care-assessments-page" showGap={false}>
        <FindCareAssessmentSlides
          questionnaire={questionnaire as Questionnaire}
          assessmentType={assessmentType}
          connectNowEnabled={connectNowEnabled}
        />
      </FindCarePageWrapper>
    </FindCareSlideProvider>
  );
};
