import { castArray, chain } from 'lodash';
import { JSX, ReactNode, useMemo } from 'react';
import { FieldErrors, FieldValues, UseControllerProps, useWatch } from 'react-hook-form';
import { Heading, Layout, StatusFeedback, Text } from '../../../components/core';
import { FormChips, FormInput, FormTextArea } from '../../../components/form';
import { getQuestionOptionsText, parseOptionFromKey, QuestionPlus } from '../utils/assessmentUtils';

type FormAssessmentQuestionProps<TFieldValues extends FieldValues> =
  UseControllerProps<TFieldValues> & {
    error?: FieldErrors<TFieldValues>[string];
    inputName: UseControllerProps<TFieldValues>['name'];
    inputError?: FieldErrors<TFieldValues>[string];
    isDisabled: boolean;
    question: QuestionPlus;
  };

type AriaIds = {
  descriptionId: string;
  preTextId: string;
  headingId: string;
  subTextId: string;
};

const QuestionWrapper = ({
  children,
  ariaIds,
  question,
}: {
  children: ReactNode;
  ariaIds: AriaIds;
  question: QuestionPlus;
}): JSX.Element => {
  return (
    <Layout.VStack space={6}>
      {Boolean(question.description) && (
        <Text.para id={ariaIds.descriptionId}>{question.description}</Text.para>
      )}

      {question.preText != null && <Text.para id={ariaIds.preTextId}>{question.preText}</Text.para>}

      <Heading.h3 level={2} id={ariaIds.headingId}>
        {question.text ?? ''}
      </Heading.h3>

      {question.subText != null && <Text.para id={ariaIds.subTextId}>{question.subText}</Text.para>}

      {children}
    </Layout.VStack>
  );
};

export const FormAssessmentQuestion = <TFieldValues extends FieldValues>({
  question,
  inputName,
  isDisabled,
  ...controllerProps
}: FormAssessmentQuestionProps<TFieldValues>): JSX.Element => {
  const options = useMemo(() => getQuestionOptionsText(question), [question]);

  const currentAnswer = useWatch({ control: controllerProps.control, name: controllerProps.name });

  const ariaIds: AriaIds = useMemo(
    () => ({
      descriptionId: (question.description ?? '') !== '' ? `${controllerProps.name}-desc` : '',
      preTextId: question.preText != null ? `${controllerProps.name}-pretext` : '',
      headingId: `${controllerProps.name}-label`,
      subTextId: question.subText != null ? `${controllerProps.name}-subtext` : '',
    }),
    [controllerProps.name, question],
  );

  const describedBy = [ariaIds.descriptionId, ariaIds.preTextId, ariaIds.subTextId]
    .filter(Boolean)
    .join(' ');

  if (options !== undefined) {
    const selectedOptions = parseOptionFromKey(question, currentAnswer);
    const manualInput = question.manualInput ?? undefined;
    const needsManualInput = chain(castArray(selectedOptions))
      .compact()
      .some(({ requiresManualInput }) => Boolean(requiresManualInput))
      .value();

    return (
      <QuestionWrapper ariaIds={ariaIds} question={question}>
        <FormChips
          {...controllerProps}
          aria-describedby={describedBy}
          isDisabled={isDisabled}
          isMulti={question.type?.includes('multi') ?? false}
          isRequired={question.isOptional !== true}
          options={options}
        />

        {needsManualInput && (
          <FormTextArea
            name={inputName}
            label={manualInput?.label ?? 'Please specify'}
            control={controllerProps.control}
            error={controllerProps.inputError}
            isDisabled={isDisabled}
            isRequired
            necessityIndicator
            autoFocus
            numberOfLines={3}
            placeholder={manualInput?.placeholder ?? ''}
            placeholderTextColor="secondary.700"
          />
        )}
      </QuestionWrapper>
    );
  }

  if (question.type === 'free-paragraph-field') {
    return (
      <QuestionWrapper ariaIds={ariaIds} question={question}>
        <FormTextArea
          {...controllerProps}
          aria-describedby={describedBy}
          isDisabled={isDisabled}
          isRequired={question.isOptional !== true}
          label={question.label ?? ''}
          necessityIndicator
          numberOfLines={6}
          placeholder={question?.placeholder ?? ''}
          placeholderTextColor="secondary.700"
        />
      </QuestionWrapper>
    );
  }

  if (question.type === 'free-text-field') {
    return (
      <QuestionWrapper ariaIds={ariaIds} question={question}>
        <FormInput
          {...controllerProps}
          autoComplete="off"
          aria-describedby={describedBy}
          isDisabled={isDisabled}
          isRequired={question.isOptional !== true}
          label={question.label ?? ''}
          placeholder={question?.placeholder ?? ''}
        />
      </QuestionWrapper>
    );
  }

  return (
    <StatusFeedback.warning testID="assessment-question">
      Could not parse question.
    </StatusFeedback.warning>
  );
};
