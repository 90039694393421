import React, { useEffect } from 'react';
import { Heading, Layout } from '../../components/core';
import { useLogout } from '../../components/layout/app/hooks/useLogout';
import { PageContent, PageFooter } from '../../components/page';
import { getRoute, useNavigate } from '../../routes';
import { getStylesheet } from '../../styles';
import { useTitle } from '../../utils/useTitle';

export const LogoutPage = (): JSX.Element => {
  const title = 'Logging you out';
  useTitle(title);

  const navigate = useNavigate();
  const [logout] = useLogout({
    onCompleted: async () => {
      navigate(getRoute('login', {}));
    },
  });

  useEffect(() => {
    void logout();
  }, []);

  return (
    <PageContent {...styles.mainStack} testID="logout-page">
      <Layout.VStack space={3}>
        <Heading.h3 center>{`${title}...`}</Heading.h3>

        <PageFooter />
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  mainStack: {
    paddingTop: 6,
  },
});
