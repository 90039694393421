import { differenceInMinutes } from 'date-fns';
import { format } from 'date-fns-tz';
import { JSX, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { IconCalendar } from '../../../components/icons/IconCalendar';
import { useLoadTrackerContext } from '../../../contexts/loadTrackerContext';
import { useNextHubExternalAppointmentQuery } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { visuallyHiddenFloatStyle } from '../../../utils/accessibility';
import { getFormatTzOptions } from '../../../utils/date';

export const ExternalAppointmentWidget = (): JSX.Element => {
  const navigate = useNavigate();

  const { data: appointmentData, loading: appointmentLoading } =
    useNextHubExternalAppointmentQuery();

  const { loading: trackerLoading, useRegisterComponent } = useLoadTrackerContext();
  useRegisterComponent(appointmentLoading);

  const appointment = appointmentData?.nextHubExternalAppointment;

  const { duration, formattedAppointmentStart } = useMemo(
    () => getAppointmentDetails(appointment?.start, appointment?.end),
    [appointment],
  );

  if (appointmentLoading || trackerLoading) {
    return <></>;
  }

  if (appointment == null) {
    return <></>;
  }

  const onViewPress = (): void => {
    navigate(getRoute('patientPortal', {}, { mrn: appointment.mrn }));
  };

  return (
    <Layout.HStack {...styles.appointmentWidget} space={3}>
      <IconCalendar {...styles.icon} aria-hidden size={6} />

      <Layout.VStack space={4} flex={1}>
        <Layout.HStack {...styles.appointmentWidgetTop} space={2}>
          <Layout.VStack {...styles.appointmentText} space={2}>
            <Heading.h5 level={2}>Upcoming appointment</Heading.h5>

            <Layout.Flex {...styles.appointmentTextDetails}>
              <Heading.h6 {...visuallyHiddenFloatStyle} level={3}>
                Appointment Start Time
              </Heading.h6>
              <Text.caption>{formattedAppointmentStart}</Text.caption>

              <Text.caption aria-hidden>|</Text.caption>

              <Heading.h6 {...visuallyHiddenFloatStyle} level={3}>
                Appointment Duration
              </Heading.h6>
              <Text.caption>{`${duration} min`}</Text.caption>
            </Layout.Flex>
          </Layout.VStack>

          <Button.successMedium
            alignSelf="center"
            aria-label="View Upcoming Appointment (opens in new tab)"
            isLink
            onPress={onViewPress}
            testID="button-view-appointment"
          >
            View
          </Button.successMedium>
        </Layout.HStack>
      </Layout.VStack>
    </Layout.HStack>
  );
};

type AppointmentDetails = {
  duration: number;
  formattedAppointmentStart: string;
};

const getAppointmentDetails = (startDate?: string, endDate?: string): AppointmentDetails => {
  if (startDate === undefined || endDate === undefined) {
    return {
      duration: 0,
      formattedAppointmentStart: '',
    };
  }

  const eventStart = new Date(startDate);
  const eventEnd = new Date(endDate);
  const formatTzOptions = getFormatTzOptions();

  return {
    duration: differenceInMinutes(eventEnd, eventStart),
    formattedAppointmentStart: format(eventStart, "EEE, MMM d 'at' h:mm aa z", formatTzOptions),
  };
};

const styles = getStylesheet({
  appointmentText: {
    flexShrink: 1,
  },

  appointmentTextDetails: {
    flexDirection: 'row',
    gap: 1,
  },

  appointmentTextTop: {
    flexShrink: 1,
    flexWrap: 'wrap',
  },

  appointmentWidget: {
    backgroundColor: 'white',
    borderLeftColor: 'black',
    borderLeftWidth: 8,
    borderRadius: 4,
    padding: 3,
  },

  appointmentWidgetTop: {
    justifyContent: 'space-between',
  },

  icon: {
    color: 'black',
    minWidth: 6,
  },
});
