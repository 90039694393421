import { RecommendationOptions, useCareRecommendationGetForUserQuery } from '../../../graphQL';

export type CareRecommendationOutputs = {
  careRecommendation: RecommendationOptions | undefined;
  connectNowEnabled: boolean;
  togetherAllEnabled: boolean;
  isUserDeactivated?: boolean;
};

type UseCareRecommendationReturn = [
  CareRecommendationOutputs,
  {
    loading: boolean;
    loaded: boolean;
    error?: Error;
  },
];

export const useCareRecommendation = (): UseCareRecommendationReturn => {
  const {
    data: careRecommendationData,
    loading: careRecommendationLoading,
    error: careRecommendationError,
  } = useCareRecommendationGetForUserQuery();

  const { careRecommendation, connectNowEnabled, togetherAllEnabled, isUserDeactivated } =
    careRecommendationData?.careRecommendationGetForUser ?? {
      careRecommendation: undefined,
      connectNowEnabled: false,
      togetherAllEnabled: false,
      isUserDeactivated: undefined,
    };

  return [
    {
      careRecommendation: careRecommendation ?? undefined,
      connectNowEnabled,
      togetherAllEnabled,
      isUserDeactivated: isUserDeactivated ?? undefined,
    },
    {
      loading: careRecommendationLoading,
      loaded: !careRecommendationLoading,
      error: careRecommendationError,
    },
  ];
};
